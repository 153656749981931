import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { BrandColorComponent } from './brand-color/brand-color.component';
import { ConversationComponent } from './conversation/conversation.component';
import { InformationPageComponent } from './information-page/information-page.component';
import { ConversationListComponent } from './conversation-list/conversation-list.component';

const ROUTES: Routes = [
  {path: '', redirectTo: '/conversation', pathMatch: 'full'},
  {path: 'conversation', pathMatch: 'full',
    component: ConversationListComponent
  },
  {path: 'conversation/:hash',
    component: BrandColorComponent,
    children: [
      {path: '', component: ConversationComponent},
      {path: 'info', component: InformationPageComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { enableTracing: !environment.production })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
