<div class="navbar">
  <div class="spacer"><a *ngIf="backArrowVisible && backLink" (click)="click()" mat-icon-button><mat-icon>arrow_back</mat-icon></a></div>
  <div class="center"><h1>{{ title }}</h1></div>
  <div class="spacer">
    <button *ngIf="menu" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>


<div class="error-msg" *ngIf="!isConnected() || !isLiveBackend()">
  <span *ngIf="!isConnected()">Connecting...</span>
  <span *ngIf="!isLiveBackend()">STAGING BACKEND</span>
</div>
