import { Type } from 'class-transformer';
import { serializeType } from './serialize-type';
import { isNullOrUndefined } from 'util';

export class LatLng {
  public latitude: number;
  public longitude: number;
  constructor() { }

  public static latLng(latitude: number, longitude: number) {
    const action: LatLng = new LatLng();
    action.latitude = latitude;
    action.longitude = longitude;
    return action;
  }

  public toString(): string {
    return `LatLng: ${this.latitude}/${this.longitude}`;
  }
}

export class SuggestedReply {

  public text: string;
  public postbackData: string;

  constructor() { }

  public static suggestedReply(text: string, postbackData: string): SuggestedReply {
    const suggestedReply = new SuggestedReply();
    suggestedReply.text = text;
    suggestedReply.postbackData = postbackData;
    return suggestedReply;
  }

  public toString(): string {
    return `${this.text}`;
  }
}

export class ShareLocationAction {
  public static create(): ShareLocationAction {
    return new ShareLocationAction();
  }
}

export class DialAction {

  public phoneNumber: string;

  constructor() { }

  public static create(phoneNumber: string) {
    const action: DialAction = new DialAction();
    action.phoneNumber = phoneNumber;
    return action;
  }
}

export class ViewLocationAction {

  @Type(serializeType(LatLng))
  public latLong: LatLng;
  public label: string;
  public query: string;

  constructor() { }

  public static create(latitude?: number, longitude?: number, label?: string, query?: string): ViewLocationAction {
    const action = new ViewLocationAction();
    if (latitude) {
      action.latLong = new LatLng();
      action.latLong.latitude = latitude;
      action.latLong.longitude = longitude;
    }
    if (label) {
      action.label = label;
    }
    if (query) {
      action.query = query;
    }
    return action;
  }
}

export class CreateCalendarEventAction {
  public startTime: string;
  public endTime: string;
  public title: string;
  public description: string;

  constructor() { }

  public static create(startTime: string, endTime: string, title: string, description: string): CreateCalendarEventAction {
    const action: CreateCalendarEventAction = new CreateCalendarEventAction();
    action.startTime = startTime;
    action.endTime = endTime;
    action.title = title;
    action.description = description;
    return action;
  }
}

export class OpenUrlAction {
  public url: string;
  public request: boolean;


  constructor() { }

  public static create(url: string, request: boolean): OpenUrlAction {
    const action: OpenUrlAction = new OpenUrlAction();
    action.url = url;
    action.request = request;
    return action;
  }
}

export class SuggestedAction extends SuggestedReply {

  @Type(serializeType(DialAction))
  public dialAction: DialAction;
  @Type(serializeType(ViewLocationAction))
  public viewLocationAction: ViewLocationAction;
  @Type(serializeType(CreateCalendarEventAction))
  public createCalendarEventAction: CreateCalendarEventAction;
  @Type(serializeType(OpenUrlAction))
  public openUrlAction: OpenUrlAction;
  @Type(serializeType(ShareLocationAction))
  public shareLocationAction: ShareLocationAction;

  constructor() {
    super();
  }

  public static openUrlAction(text: string, request: boolean, postbackData: string, url: string): SuggestedAction {
    const suggestedAction = SuggestedAction.baseSuggestedAction(text, postbackData);
    suggestedAction.openUrlAction = OpenUrlAction.create(url, request);
    return suggestedAction;
  }

  public static dialAction(text: string, postbackData: string, phoneNumber: string): SuggestedAction {
    const suggestedAction = SuggestedAction.baseSuggestedAction(text, postbackData);
    suggestedAction.dialAction = DialAction.create(phoneNumber);
    return suggestedAction;
  }

  public static viewLocationAction(text: string, postbackData: string, latitude?: number, longitude?: number, label?: string, query?: string): SuggestedAction {
    const suggestedAction = SuggestedAction.baseSuggestedAction(text, postbackData);
    suggestedAction.viewLocationAction = ViewLocationAction.create(latitude, longitude, label, query);
    return suggestedAction;
  }

  public static createCalendarEventAction(text: string, postbackData: string, startTime: string, endTime: string, title: string, description: string): SuggestedAction {
    const suggestedAction = SuggestedAction.baseSuggestedAction(text, postbackData);
    suggestedAction.createCalendarEventAction = CreateCalendarEventAction.create(startTime, endTime, title, description);
    return suggestedAction;
  }

  public static shareLocationAction(text: string, postbackData: string): SuggestedAction {
    const suggestedAction = SuggestedAction.baseSuggestedAction(text, postbackData);
    suggestedAction.shareLocationAction = new ShareLocationAction();
    return suggestedAction;
  }

  private static baseSuggestedAction(text: string, postbackData: string): SuggestedAction {
    const suggestedAction = new SuggestedAction();
    suggestedAction.text = text;
    suggestedAction.postbackData = postbackData;
    return suggestedAction;
  }

  public getType(): string {
    // do not edit the returned values. this are the icon names
    if (!isNullOrUndefined(this.shareLocationAction)) {
      return 'location_searching';
    } else if (!isNullOrUndefined(this.dialAction)) {
      return 'call';
    } else if (!isNullOrUndefined(this.openUrlAction)) {
      if (this.openUrlAction.request === undefined || this.openUrlAction.request === null) {
          return 'public';
      } else {
        return 'credit_card';
      }
    } else if (!isNullOrUndefined(this.viewLocationAction)) {
      return 'place';
    } else if (!isNullOrUndefined(this.createCalendarEventAction)) {
      return 'event';
    }
  }

  public toString(): string {
    return `type: ` + this.getType();
  }

}

export class SuggestionChip {

  @Type(serializeType(SuggestedReply))
  public reply: SuggestedReply;

  @Type(serializeType(SuggestedAction))
  public action: SuggestedAction;

  constructor() {
  }

  public static suggestedReplyChip(text: string, postbackData: string): SuggestionChip {
    const suggestionChip = new SuggestionChip();
    suggestionChip.reply = SuggestedReply.suggestedReply(text, postbackData);
    return suggestionChip;
  }

  public static suggestedActionChip(action: SuggestedAction): SuggestionChip {
    const suggestionChip = new SuggestionChip();
    suggestionChip.action = action;
    return suggestionChip;
  }

  isReply() {
    return isNullOrUndefined(this.action);
  }

  public toString(): string {
    return `{reply: '${this.reply}', action: '${this.action}'}`;
  }
}
