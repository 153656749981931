import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { OverlayModule } from '@angular/cdk/overlay';

import { MomentModule } from 'ngx-moment';
import 'moment/locale/de';
import 'moment/locale/pl';
import 'moment/locale/fi';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DatabaseModule } from './database/database.module';
import { MessageService } from './conversation/services/message.service';
import { stompConfig } from './conversation/services/stomp/websocket-config';
import { RxStompService } from './conversation/services/stomp/rx-stomp.service';
import { rxStompServiceFactory } from './conversation/services/stomp/rx-stomp-service-factory';
import { ConversationClientService } from './conversation/services/stomp/conversation-client.service';
import { MessageRepositoryService } from './conversation/services/store/message-repository.service';
import { ConversationModule } from './conversation/conversation.module';
import { BrandInformationService } from './conversation/services/brand-information.service';
import { InformationPageModule } from './information-page/information-page.module';
import { BrandColorModule } from './brand-color/brand-color.module';
import { MapLocationPickerComponent } from './shared/map-location-picker/map-location-picker.component';
import { MapLocationPickerService } from './shared/map-location-picker.service';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IcalService } from './conversation/services/ical.service';
import { WindowRefService } from './conversation/services/window-ref.service';
import { StaticMapService } from './conversation/services/static-map.service';
import { ImageOverlayService } from './shared/image-overlay.service';
import { ConversationListModule } from './conversation-list/conversation-list.module';
import { ImageService } from './conversation/services/images/image.service';
import { SafeMediaUrlProviderFactory } from './conversation/services/images/safe-media-url-provider-factory.service';
import { HashService } from './shared/hash.service';
import { FileRepositoryService } from './shared/file-repository.service';
import { GlobalErrorHandler } from './core/global-error-handler';
import { HttpService } from './conversation/services/http-service';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    AppRoutingModule,
    ConversationListModule,
    ConversationModule,
    InformationPageModule,
    BrandColorModule,
    DatabaseModule,
    OverlayModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatCardModule,
      ServiceWorkerModule.register('/custom-service-worker.js', {
      enabled: environment.production
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googlemaps.apikey,
      libraries: ['places']
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory
    },
    ConversationClientService,
    MessageRepositoryService,
    MessageService,
    BrandInformationService,
    IcalService,
    WindowRefService,
    MapLocationPickerService,
    ImageOverlayService,
    StaticMapService,
    ImageService,
    HttpService,
    SafeMediaUrlProviderFactory,
    FileRepositoryService,
    HashService
  ],
  declarations: [
    AppComponent,
    MapLocationPickerComponent
  ],
  entryComponents: [
    MapLocationPickerComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
