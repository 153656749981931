import { deserialize, serialize } from 'class-transformer';

import { Party } from './party';
import { AbstractMessage } from './abstract-message';
import { AgentMessage } from './agent-message';
import { ClientMessage, ClientMessageAck } from './client-message';

export class MessageMapper {

  public static fromJson(jsonString: string): AbstractMessage {
    const jsonObject = JSON.parse(jsonString);

    if (jsonObject['party'] === Party.AGENT) {
      return MessageMapper.agentMessageFromJson(jsonString);
    } else if (jsonObject['party'] === Party.CLIENT) {
      return MessageMapper.clientMessageFromJson(jsonString);
    } else {
      throw new Error('Unknown type of message Party');
    }
  }

  public static agentMessageFromJson(jsonString: string): AgentMessage {
    return deserialize(AgentMessage, jsonString);
  }

  public static clientMessageFromJson(jsonString: string): ClientMessage {
    return deserialize(ClientMessage, jsonString);
  }

  public static clientMessageAckFromJson(jsonString: string): ClientMessageAck {
    return deserialize(ClientMessageAck, jsonString);
  }

  public static toJson(message: AbstractMessage): string {
    return serialize(message);
  }
}

