import { Injectable } from '@angular/core';
import * as sha256 from 'fast-sha256';

@Injectable({
  providedIn: 'root'
})
export class HashService {

  constructor() { }

  public generateHash(data: Uint8Array): string {
    const hasher = new sha256.Hash();
    hasher.update(data);
    const result = hasher.digest();
    return this.bufferToHex(result);
  }

  private bufferToHex (buffer: Uint8Array) {
    return Array
      .from (buffer)
      .map (b => b.toString (16).padStart (2, '0'))
      .join ('');
  }

}
