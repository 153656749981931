import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractMessage } from '../model/abstract-message';
import { AgentMessage } from '../model/agent-message';
import { Party } from '../model/party';
import { CustomerBrandInformation } from '../model/customer-brand-information';
import { SuggestionChip } from '../model/suggestion';

@Component({
  selector: 'app-conversation-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
})
export class MessageComponent implements AfterViewInit {

  @ViewChild('messageComponent', { static: false }) messageDiv: ElementRef;

  @Input() message: AbstractMessage;
  @Input() brandInformation: CustomerBrandInformation;
  @Output() doScrollingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public hasSuggestions(): boolean {
    return (this.message.isMessageType(Party.AGENT) && (<AgentMessage>this.message).hasSuggestions());
  }

  ngAfterViewInit() {
    if (!this.message.hasImage()) {
      this.messageDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }



  public getSuggestions():  Array<SuggestionChip> {
    if (this.message.isMessageType(Party.AGENT)) {
     return (<AgentMessage>this.message).suggestions;
    }
  }

  imageLoadedEvent() {
    this.doScrollingEvent.emit(true);
  }

  getMessageId(): string {
    return this.message.id;
  }

  hasNoCard(): boolean {
    return !(this.message.hasStandaloneCard() || this.message.hasCarouselCard());
  }

  getLocale(): string {
    const brandLocale = this.brandInformation ? this.brandInformation.locale : undefined;
    return brandLocale || 'en';
  }

}
