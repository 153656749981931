<div class="head">{{brandInformation.translations.brand.informationHeadline}}</div>
<ul>
  <li *ngIf="brandInformation.phone as myPhone">
    <div class="icon"><i class="material-icons">call</i></div>
    <div class="main">
      <a href="tel:{{myPhone}}">{{myPhone}}</a>
      <div class="description">{{brandInformation.translations.brand.details.phone}}</div>
    </div>
  </li>
  <li *ngIf="brandInformation.website as myWebsite">
    <div class="icon"><i class="material-icons">public</i></div>
    <div class="main">
      <span [innerHTML]="myWebsite | linky:{ className: 'link-no-decoration' }"></span>
      <div class="description">{{brandInformation.translations.brand.details.website}}</div>
    </div>
  </li>
  <li *ngIf="brandInformation.email as myEmail">
    <div class="icon"><i class="material-icons">email</i></div>
    <div class="main">
      <a href="mailto:{{myEmail}}">{{myEmail}}</a>
      <div class="description">{{brandInformation.translations.brand.details.email}}</div>
    </div>
  </li>
  <li *ngIf="brandInformation.urlPrivacyPolicy as myURL">
    <div class="icon"></div>
    <div class="main">
      <a href="{{myURL}}" target="_blank">{{brandInformation.translations.brand.details.privacyPolicyLink}}</a>
      <div class="description"></div>
    </div>
  </li>
  <li *ngIf="brandInformation.urlTermsOfService as myURL">
    <div class="icon"></div>
    <div class="main">
      <a href="{{myURL}}" target="_blank">{{brandInformation.translations.brand.details.termsOfServiceLink}}</a>
      <div class="description"></div>
    </div>
  </li>
</ul>
