import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { ClientMessage } from '../model/client-message';
import { ImageService } from '../services/images/image.service';
import { MessageService } from '../services/message.service';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaFile } from '../model/abstract-message';
import { WindowRefService } from '../services/window-ref.service';
import { CustomerBrandInformation } from '../model/customer-brand-information';
export class MessageSubmitFormComponent {
    constructor(messageService, windowRefService, domSanitizer, imageService) {
        this.messageService = messageService;
        this.windowRefService = windowRefService;
        this.domSanitizer = domSanitizer;
        this.imageService = imageService;
        this.MAX_FILE_SIZE = 10 * 1024 * 1024;
        this.MAX_LOCAL_IMAGE_WIDTH_HEIGHT = 1024;
        this.SUPPORTED_MIMETYPES = ['image/gif', 'image/png', 'image/jpeg', 'image/jpg'];
        this.onFocusEvent = new EventEmitter();
        this.errorMessage = null;
        this.uploadProgressValue = null;
        this._window = this.windowRefService.getWindowRef();
    }
    ngOnInit() {
    }
    ngOnDestroy() {
        this.resetImage();
    }
    inputFocusEvent($event) {
        this.onFocusEvent.emit($event);
    }
    submitMessage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.isFormValid()) {
                return;
            }
            this.errorMessage = null;
            if (this.mediaFile) {
                // Send File
                const fileData = yield this.fileConvertProgress;
                const mediaMsg = ClientMessage.clientMediaFileMessage(UUID.UUID(), this.mediaFile);
                this.uploadProgressValue = 0;
                try {
                    yield this.messageService.sendMediaClientMessage(this.conversationHash, mediaMsg, fileData, fileData, percent => this.uploadProgressValue = percent);
                }
                catch (e) {
                    console.error(e);
                    this.errorMessage = this.brandInformation ? this.brandInformation.translations['conversation']['sendMessage']['errorFileUploadFailed'] : 'Upload failed! Please try again.';
                }
                this.uploadProgressValue = null;
            }
            // Send Text Message
            this.sendTextMessage();
            this.messageForm.reset();
            this.cancelImage();
        });
    }
    inputMouseMove(event) {
        if (event.cancelable) {
            event.preventDefault();
        }
        event.stopPropagation();
    }
    sendTextMessage() {
        if (this.msgInput.nativeElement.value.length > 0) {
            const msg = ClientMessage.clientTextMessage(UUID.UUID(), this.msgInput.nativeElement.value);
            this.messageService.sendTextClientMessage(this.conversationHash, msg);
        }
    }
    get isConnected() {
        return this.messageService.isConnected();
    }
    get isFormDisabled() {
        return !this.isConnected || this.uploadProgressValue != null;
    }
    isFormValid() {
        return !!this.mediaFile || this.msgInput.nativeElement.value.length > 0;
    }
    selectImage() {
        this.fileInput.nativeElement.click();
    }
    onFileChange(event) {
        this.resetImage();
        const file = event.target.files[0];
        if (!this.isFileTypeCorrect(file)) {
            this.errorMessage = this.brandInformation ? this.brandInformation.translations['conversation']['sendMessage']['errorFileTypeNotSupported'] : 'File type is not supported.';
            this.cancelImage();
            return;
        }
        else if (!this.isFileSizeCorrect(file)) {
            this.errorMessage = this.brandInformation ? this.brandInformation.translations['conversation']['sendMessage']['errorFileSizeTooBig'] : 'File size is too big.';
            this.cancelImage();
            return;
        }
        else {
            this.errorMessage = null;
        }
        const mediaFile = new MediaFile();
        mediaFile.mimeType = file.type;
        mediaFile.contentLength = file.size;
        this.mediaFile = mediaFile;
        // Show Preview
        this.registerBlobUrl(file);
        this.previewImage = this.domSanitizer.bypassSecurityTrustUrl(this.blobUrl);
        // Convert File to Array
        this.fileConvertProgress = this.imageService.fileToArray(file);
    }
    cancelImage() {
        this.resetImage();
        this.fileInput.nativeElement.value = '';
    }
    resetImage() {
        if (this.previewImage) {
            this.previewImage = null;
        }
        this.unregisterBlobUrl();
        this.mediaFile = null;
    }
    isFileTypeCorrect(file) {
        return file && file.type && this.SUPPORTED_MIMETYPES.some(t => t === file.type);
    }
    isFileSizeCorrect(file) {
        return file && file.size <= this.MAX_FILE_SIZE;
    }
    unregisterBlobUrl() {
        if (this.blobUrl) {
            this._window.URL.revokeObjectURL(this.blobUrl);
            this.blobUrl = null;
        }
    }
    registerBlobUrl(file) {
        this.unregisterBlobUrl();
        this.blobUrl = this._window.URL.createObjectURL(file);
        return this.blobUrl;
    }
}
