import { Type } from 'class-transformer';
import { serializeType } from './serialize-type';
import { SuggestionChip } from './suggestion';
import { MediaFile } from './abstract-message';

export enum CardOrientation {
    TOP, LEFT, RIGHT
}

export enum CarouselCardWidth {
    SMALL, MEDIUM
}

export class CardContent {
    public title: String;
    public description: String;
    @Type(serializeType(MediaFile))
    public mediaFile: MediaFile;
    @Type(serializeType(SuggestionChip))
    public suggestions: Array<SuggestionChip>;

    public toString(): string {
        return `(title: '${this.title}', mediaFile: '${this.mediaFile}', suggestions: ${this.suggestions}, description: '${this.description}')`;
    }
}

export class StandaloneCard {
    public orientation: CardOrientation;
    @Type(serializeType(CardContent))
    public content: CardContent;

    public hasImage() {
        return this.content && this.content.mediaFile && this.content.mediaFile.isImage();
    }

    public toString(): string {
        return `(content: '${this.content}', orientation: '${this.orientation}')`;
    }
}

export class CarouselCard {
    public width: CarouselCardWidth;
    @Type(serializeType(CardContent))
    public cards: Array<CardContent>;

    public toString(): string {
        return `(cards: '${this.cards}', width: '${this.width}')`;
    }
}
