import { Party } from './party';
import { Transform, Type } from 'class-transformer';
import { serializeType } from './serialize-type';
import { ImageBase64Utils } from './image-base64-utils';

/** @deprecated Used ony for DB upgrade process */
export class PersistentImage {
  public type: string;
  @Transform(ImageBase64Utils.imageBytesToBase64, { toPlainOnly: true })
  @Transform(ImageBase64Utils.base64ToImageBytes, { toClassOnly: true })
  public data: number[];
}

export class MediaFile {
  public fileUrl: string;
  public thumbnailUrl: string;
  public contentDescription: string;

  public mimeType: string;
  public contentLength: number;

  /** @deprecated Used ony for DB upgrade process */
  @Transform(ImageBase64Utils.imageBytesToBase64, { toPlainOnly: true })
  @Transform(ImageBase64Utils.base64ToImageBytes, { toClassOnly: true })
  public imageData: number[];

  public filesId: string;

  public thumbnailMimeType: string;
  public thumbnailContentLength: number;
  /** @deprecated Used ony for DB upgrade process */
  @Transform(ImageBase64Utils.imageBytesToBase64, { toPlainOnly: true })
  @Transform(ImageBase64Utils.base64ToImageBytes, { toClassOnly: true })
  public thumbnailData: number[];

  public thumbnailFilesId: string;

  public hasThumbnail(): boolean {
    return (this.thumbnailUrl != null || this.thumbnailFilesId != null);
  }

  public isImage(): boolean {
    return this.mimeType.startsWith('image/');
  }

  public isVideo(): boolean {
    return this.mimeType.startsWith('video/');
  }

  public isAudio(): boolean {
    return this.mimeType.startsWith('audio/');
  }

  public toString(): string {
    return `(url: '${this.fileUrl}', thumbnailUrl: '${this.thumbnailUrl}', fileId: '${this.filesId}', thumbnailFileId: '${this.thumbnailFilesId}', `
        + `contentDescription: ${this.contentDescription}, mimeType: '${this.mimeType}', contentLength: ${this.contentLength})`;
  }
}

export abstract class AbstractMessage {
  public id: string;
  public party: Party;
  public timestamp: number;
  public text: string;
  public hideFreeText = false;
  @Type(serializeType(PersistentImage))
  public persistentImage: PersistentImage;
  @Type(serializeType(MediaFile))
  public mediaFile: MediaFile;

  public getParty(): string {
    return Party[this.party];
  }

  public isMessageType(party: Party): boolean {
    return this.party === party;
  }

  public hasPersistentImage(): boolean {
    return this.persistentImage != null && this.persistentImage.type.startsWith('image/');
  }

  public hasImage(): boolean {
    return this.hasMediaFile() && this.mediaFile.isImage();
  }

  public hasVideo(): boolean {
    return this.hasMediaFile() && this.mediaFile.isVideo();
  }

  public hasAudio(): boolean {
    return this.hasMediaFile() && this.mediaFile.isAudio();
  }

  private hasMediaFile() {
    return this.mediaFile != null;
  }

  public hasText(): boolean {
    return this.text != null;
  }

  public hasStandaloneCard(): boolean {
    return false;
  }

  public hasCarouselCard(): boolean {
    return false;
  }

  public toString(): string {
    return `(id: '${this.id}', party: '${this.getParty()}', timestamp: ${this.timestamp}, text: '${this.text}', mediaFile: '${this.mediaFile}')`;
  }

}
