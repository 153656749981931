/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rich-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../card-content/card-content.component.ngfactory";
import * as i3 from "../card-content/card-content.component";
import * as i4 from "../../../shared/image-overlay.service";
import * as i5 from "../../services/images/safe-media-url-provider-factory.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./rich-card.component";
var styles_RichCardComponent = [i0.styles];
var RenderType_RichCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RichCardComponent, data: {} });
export { RenderType_RichCardComponent as RenderType_RichCardComponent };
function View_RichCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-content", [], null, null, null, i2.View_CardContentComponent_0, i2.RenderType_CardContentComponent)), i1.ɵdid(1, 114688, null, 0, i3.CardContentComponent, [i4.ImageOverlayService, i5.SafeMediaUrlProviderFactory, i6.DomSanitizer], { cardContent: [0, "cardContent"], cardOrientation: [1, "cardOrientation"], messageId: [2, "messageId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message.standaloneCard.content; var currVal_1 = _co.message.standaloneCard.orientation; var currVal_2 = _co.message.id; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_RichCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RichCardComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.message && _co.message.hasStandaloneCard()); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RichCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rich-card", [], null, null, null, View_RichCardComponent_0, RenderType_RichCardComponent)), i1.ɵdid(1, 49152, null, 0, i8.RichCardComponent, [], null, null)], null, null); }
var RichCardComponentNgFactory = i1.ɵccf("app-rich-card", i8.RichCardComponent, View_RichCardComponent_Host_0, { message: "message" }, {}, []);
export { RichCardComponentNgFactory as RichCardComponentNgFactory };
