export const environment = {
  translations: {
    en: {
      brand: {
        informationHeadline: 'Information',
        loading: 'loading ...',
        details: {
          phone: 'Phone',
          website: 'Home Page',
          email: 'Email',
          privacyPolicyLink: 'Privacy Policy',
          termsOfServiceLink: 'Terms of Service'
        },
        actions: {
          call: 'CALL',
          website: 'WEBSITE',
          email: 'E-MAIL'
        }
      },
      conversation: {
        messages: {
          waitingForMessages: 'Waiting for messages ...',
          scrollToBottomButtonTooltip: 'Button to scroll to bottom',
          errorHtml5AudioNotSupported: 'Unfortunately, your browser does not support HTML5 audio.'
        },
        sendMessage: {
          sending: 'Sending...',
          messageInputPlaceholder: 'Type a message',
          errorFileUploadFailed: 'Upload failed! Please try again.',
          errorFileTypeNotSupported: 'File type is not supported.',
          errorFileSizeTooBig: 'File size is too big.'
        },
        shareLocation: {
          searchLocation: 'search for location',
          detectingPosition: 'Detecting position',
          shareLocationButton: 'Share location',
          abortButton: 'Abort'
        }
      }
    },
    de: {
      brand: {
        informationHeadline: 'Information',
        loading: 'Laden ...',
        details: {
          phone: 'Telefon',
          website: 'Webseite',
          email: 'Email',
          privacyPolicyLink: 'Datenschutzerklärung',
          termsOfServiceLink: 'Allgemeine Geschäftsbedingungen'
        },
        actions: {
          call: 'ANRUF',
          website: 'WEBSEITE',
          email: 'E-MAIL'
        }
      },
      conversation: {
        messages: {
          waitingForMessages: 'Warten auf Nachrichten ...',
          scrollToBottomButtonTooltip: 'Button um ganz nach unten zu scrollen',
          errorHtml5AudioNotSupported: 'Leider unterstützt dieser Browser kein HTML5 Audio.'
        },
        sendMessage: {
          sending: 'Senden ...',
          messageInputPlaceholder: 'Gib eine Nachricht ein',
          errorFileUploadFailed: 'Datei konnte nicht gesendet werden! Bitte nochmal versuchen.',
          errorFileTypeNotSupported: 'Dieser Dateityp wird nicht unterstützt.',
          errorFileSizeTooBig: 'Diese Datei ist zu groß.'
        },
        shareLocation: {
          searchLocation: 'Ort suchen',
          detectingPosition: 'Position ermitteln',
          shareLocationButton: 'Position teilen',
          abortButton: 'Abbrechen'
        }
      }
    },
    nb: {
      brand: {
        informationHeadline: 'Informasjon',
        loading: 'Laster ...',
        details: {
          phone: 'Telefon',
          website: 'Hjemmeside',
          email: 'E-post',
          privacyPolicyLink: 'Personvern',
          termsOfServiceLink: 'Tjenestevilkår'
        },
        actions: {
          call: 'RING',
          website: 'NETTSTED',
          email: 'E-POST'
        }
      },
      conversation: {
        messages: {
          waitingForMessages: 'Venter på meldinger ...',
          scrollToBottomButtonTooltip: 'Knapp for å bla deg til bunnen',
          errorHtml5AudioNotSupported: 'Dessverre støtter ikke nettleseren din HTML5 lyd.'
        },
        sendMessage: {
          sending: 'Sender...',
          messageInputPlaceholder: 'Skriv en melding',
          errorFileUploadFailed: 'Upplastning feilet, vennligst prøv igjen.',
          errorFileTypeNotSupported: 'Filtypen støttes ikke.',
          errorFileSizeTooBig: 'Filen er for stor.'
        },
        shareLocation: {
          searchLocation: 'Søk etter lokasjon',
          detectingPosition: 'Finner posisjon',
          shareLocationButton: 'Del lokasjon',
          abortButton: 'Avbryt'
        }
      }
    },
    fr: {
      brand: {
        informationHeadline: 'Information',
        loading: 'chargement ...',
        details: {
          phone: 'Téléphone',
          website: 'Accueil',
          email: 'E-mail',
          privacyPolicyLink: 'Politique de confidentialité',
          termsOfServiceLink: 'Conditions d\'utilisation'
        },
        actions: {
          call: 'APPEL',
          website: 'SITE WEB',
          email: 'E-MAIL'
        }
      },
      conversation: {
        messages: {
          waitingForMessages: 'En attente des messages ...',
          scrollToBottomButtonTooltip: 'Bouton pour descendre en bas de page',
          errorHtml5AudioNotSupported: 'Malheureusement, votre navigateur ne supporte pas le HTML5 audio.'
        },
        sendMessage: {
          sending: 'Envoi en cours...',
          messageInputPlaceholder: 'Rédigez un message',
          errorFileUploadFailed: 'Echec du téléchargement! Merci de réessayer.',
          errorFileTypeNotSupported: 'Ce type de fichier n\'est pas accepté.',
          errorFileSizeTooBig: 'Fichier trop lourd.'
        },
        shareLocation: {
          searchLocation: 'Recherche de la localisation',
          detectingPosition: 'Détection de la position',
          shareLocationButton: 'Partager la localisation',
          abortButton: 'Abandon'
        }
      }
    },
    es: {
      brand: {
        informationHeadline: 'Información',
        loading: 'cargando ...',
        details: {
          phone: 'Teléfono',
          website: 'Página Web',
          email: 'Email',
          privacyPolicyLink: 'Política de Privacidad',
          termsOfServiceLink: 'Términos del servicio'
        },
        actions: {
          call: 'LLAMAR',
          website: 'WEB',
          email: 'E-MAIL'
        }
      },
      conversation: {
        messages: {
          waitingForMessages: 'Esperando por los mensajes ...',
          scrollToBottomButtonTooltip: 'Botón para desplazarse hacia abajo',
          errorHtml5AudioNotSupported: 'Desfortunadamente, tu buscador no soporta HTML5 audio.'
        },
        sendMessage: {
          sending: 'Enviando...',
          messageInputPlaceholder: 'Escriba un mensaje',
          errorFileUploadFailed: 'Subida fallida! Inténtalo de nuevo.',
          errorFileTypeNotSupported: 'El tipo de archive no es compatible.',
          errorFileSizeTooBig: 'El tamaño del archivo es demasiado grande.'
        },
        shareLocation: {
          searchLocation: 'Buscando por localización',
          detectingPosition: 'Detectando posición',
          shareLocationButton: 'Compartir localización',
          abortButton: 'Abortar'
        }
      }
    },
    it: {
      brand: {
        informationHeadline: 'Informazioni',
        loading: 'caricamento ...',
        details: {
          phone: 'Telefono',
          website: 'Home Page',
          email: 'Email',
          privacyPolicyLink: 'Privacy Policy',
          termsOfServiceLink: 'Regolamento'
        },
        actions: {
          call: 'Chiama',
          website: 'Sito Web',
          email: 'e-mail'
        }
      },
      conversation: {
        messages: {
          waitingForMessages: 'Gentile cliente, come espressamente comunicato, siamo spiacenti di comunicarle che sono trascorsi i tempi richiesti per l’accettazione del premio.' +
            ' Grazie e buona giornata. ',
          scrollToBottomButtonTooltip: 'Scorri in basso',
          errorHtml5AudioNotSupported: 'Purtroppo il tuobrowser non supporta gli HTML5 Audio.'
        },
        sendMessage: {
          sending: 'Invio in corso...',
          messageInputPlaceholder: 'Scrivi un messaggio',
          errorFileUploadFailed: 'Upload fallito! Per favore prova di nuovo.',
          errorFileTypeNotSupported: 'Questo formato non è supportato.',
          errorFileSizeTooBig: 'Il file caricato è troppo grande.'
        },
        shareLocation: {
          searchLocation: 'Cerca la posizione',
          detectingPosition: 'Cercando la posizione',
          shareLocationButton: 'Condividi la posizione',
          abortButton: 'Cancella'
        }
      }
    },
    pl: {
      brand: {
        informationHeadline: 'Informacje',
        loading: 'ładowanie ...',
        details: {
          phone: 'Numer telefonu',
          website: 'Strona internetowa',
          email: 'E-mail',
          privacyPolicyLink: 'Polityka prywatności',
          termsOfServiceLink: 'Regulamin'
        },
        actions: {
          call: 'ZADZWOŃ',
          website: 'WITRYNA',
          email: 'E-MAIL'
        }
      },
      conversation: {
        messages: {
          waitingForMessages: 'Ładowanie wiadomości...',
          scrollToBottomButtonTooltip: 'Ten przycisk przeniesie Cię na dół',
          errorHtml5AudioNotSupported: 'Niestety, Twoja przeglądarka nie obsługuje dźwięku w HTML5.'
        },
        sendMessage: {
          sending: 'Wysyłanie...',
          messageInputPlaceholder: 'Napisz wiadomość',
          errorFileUploadFailed: 'Błąd pobierania, spróbuj ponownie',
          errorFileTypeNotSupported: 'Plik nie jest obsługiwany',
          errorFileSizeTooBig: 'Plik jest za duży'
        },
        shareLocation: {
          searchLocation: 'Wyszukaj lokalizację',
          detectingPosition: 'Wyszukiwanie lokalizacji',
          shareLocationButton: 'Udostępnij lokalizację',
          abortButton: 'Anuluj'
        }
      }
    },
    fi: {
        brand: {
        informationHeadline: 'Yhteystiedot',
        loading: 'ladataan ...',
        details: {
          phone: 'Soita meille',
          website: 'Vieraile sivustollamme',
          email: 'Lähetä sähköpostia',
          privacyPolicyLink: 'Tietosuojaseloste',
          termsOfServiceLink: 'Käyttöehdot'
        },
        actions: {
          call: 'SOITA',
          website: 'KOTISIVU',
          email: 'SÄHKÖPOSTI'
        }
      },
    conversation: {
        messages: {
          waitingForMessages: 'Odotetaan uusia viestejä ...',
          scrollToBottomButtonTooltip: 'Painike jolla pääset sivun loppuun',
          errorHtml5AudioNotSupported: 'Valitettavasti selaimesi ei tue HTML5 audiota.'
        },
        sendMessage: {
          sending: 'Lähetetään...',
          messageInputPlaceholder: 'Kirjoita viesti',
          errorFileUploadFailed: 'Lataus epäonnistui! Yritäthän myöhemmin uudelleen.',
          errorFileTypeNotSupported: 'Tiedostotyyppiä ei tueta.',
          errorFileSizeTooBig: 'Tiedoston koko on liian suuri.'
        },
        shareLocation: {
          searchLocation: 'etsi sijaintia',
          detectingPosition: 'Paikannetaan sijaintia',
          shareLocationButton: 'Jaa sijainti',
          abortButton: 'Keskeytä'
        }
      }
    },
    fi_turva: {
      brand: {
      informationHeadline: 'Yhteystiedot',
      loading: 'ladataan ...',
      details: {
        phone: 'Soita meille',
        website: 'Vieraile sivustollamme',
        email: 'Lähetä sähköpostia',
        privacyPolicyLink: 'Tietosuojaseloste',
        termsOfServiceLink: 'Käyttöehdot'
      },
      actions: {
        call: 'SOITA',
        website: 'KOTISIVU',
        email: 'SÄHKÖPOSTI'
      }
    },
  conversation: {
      messages: {
        waitingForMessages: 'Odotetaan uusia viestejä ...',
        scrollToBottomButtonTooltip: 'Painike jolla pääset sivun loppuun',
        errorHtml5AudioNotSupported: 'Valitettavasti selaimesi ei tue HTML5 audiota.'
      },
      sendMessage: {
        sending: 'Lähetetään...',
        messageInputPlaceholder: 'Hymyile, olet Turvassa.',
        errorFileUploadFailed: 'Lataus epäonnistui! Yritäthän myöhemmin uudelleen.',
        errorFileTypeNotSupported: 'Tiedostotyyppiä ei tueta.',
        errorFileSizeTooBig: 'Tiedoston koko on liian suuri.'
      },
      shareLocation: {
        searchLocation: 'etsi sijaintia',
        detectingPosition: 'Paikannetaan sijaintia',
        shareLocationButton: 'Jaa sijainti',
        abortButton: 'Keskeytä'
      }
    }
  }
  }
};
