import * as sha256 from 'fast-sha256';
import * as i0 from "@angular/core";
export class HashService {
    constructor() { }
    generateHash(data) {
        const hasher = new sha256.Hash();
        hasher.update(data);
        const result = hasher.digest();
        return this.bufferToHex(result);
    }
    bufferToHex(buffer) {
        return Array
            .from(buffer)
            .map(b => b.toString(16).padStart(2, '0'))
            .join('');
    }
}
HashService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HashService_Factory() { return new HashService(); }, token: HashService, providedIn: "root" });
