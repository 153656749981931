/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./brand-images.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./brand-images.component";
var styles_BrandImagesComponent = [i0.styles];
var RenderType_BrandImagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BrandImagesComponent, data: {} });
export { RenderType_BrandImagesComponent as RenderType_BrandImagesComponent };
export function View_BrandImagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "heroImage"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [], [[1, "src", 4]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (("url(" + _co.brandInformation.heroImageUrl) + ")")); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.brandInformation.iconUrl; _ck(_v, 4, 0, currVal_1); }); }
export function View_BrandImagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-brand-images", [], null, null, null, View_BrandImagesComponent_0, RenderType_BrandImagesComponent)), i1.ɵdid(1, 114688, null, 0, i3.BrandImagesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BrandImagesComponentNgFactory = i1.ɵccf("app-brand-images", i3.BrandImagesComponent, View_BrandImagesComponent_Host_0, { brandInformation: "brandInformation" }, {}, []);
export { BrandImagesComponentNgFactory as BrandImagesComponentNgFactory };
