import { Injectable } from '@angular/core';
import Dexie, { liveQuery, Observable } from 'dexie';
import { DatabaseService } from '../../../database/database.service';
import { Party } from '../../model/party';
import { AbstractMessage } from '../../model/abstract-message';
import { MessageMapper } from '../../model/message-mapper';

@Injectable()
export class MessageRepositoryService {

  private messages: Dexie.Table<MessageEntity, string>;

  constructor(private databaseService: DatabaseService) {
    this.messages = this.databaseService.table('messages');
  }

  public storeMessage(conversationHash: string, message: AbstractMessage): Dexie.Promise<string> {
    const entity = new MessageEntity(message.id, message.party, MessageMapper.toJson(message), message.timestamp, conversationHash);
    return this.messages.add(entity);
  }

  public updateMessage(conversationHash: string, message: AbstractMessage): Dexie.Promise<number> {
    const entity = new MessageEntity(message.id, message.party, MessageMapper.toJson(message), message.timestamp, conversationHash);
    return this.messages.update(message.id, entity);
  }


  public async loadConversation(conversationHash: string): Promise<AbstractMessage[]> {
     try {
      const entities = await this.messages.where('conversationHash')
        .equals(conversationHash)
        .sortBy('timestamp');
      return entities.map(e => MessageMapper.fromJson(e.jsonMessage));
    } catch (reason) {
      console.error(`MessageRepositoryService: could not loadConversation(${conversationHash}) because of: ${reason}`);
      return [];
    }
  }
 }

export class MessageEntity {
  conversationHash: string;
  id: string;
  party: number;
  jsonMessage: string;
  timestamp: number;

  constructor(id: string, party: number, jsonMessage: string, timestamp: number, conversationHash: string) {
    this.id = id;
    this.party = party;
    this.jsonMessage = jsonMessage;
    this.timestamp = timestamp;
    this.conversationHash = conversationHash;
  }

  public toString(): string {
    return `MessageEntity (${Party[this.party]}, id: ${this.id}, timestamp: ${this.timestamp}, hash: ${this.conversationHash})`;
  }
}
