<div class="brand-header">

    <div *ngIf="brandInformation.headline as headline" class="headline">{{headline}}</div>
    <div *ngIf="brandInformation.description as description" class="description">{{description}}</div>
</div>
<div class="actions">
    <div *ngIf="brandInformation.phone as myPhone" class="action"><a class="link-call" href="tel:{{ myPhone }}">
      <i class="material-icons">call</i><br/>{{brandInformation.translations.brand.actions.call}}
    </a></div>
    <div *ngIf="brandInformation.website as myWebsite" class="action"><a class="link-website" href="{{ myWebsite }}" target="_blank">
      <i class="material-icons">public</i><br/>{{brandInformation.translations.brand.actions.website}}
    </a></div>
    <div *ngIf="brandInformation.email as myEmail" class="action"><a class="link-email" href="mailto:{{ myEmail }}">
      <i class="material-icons">email</i><br/>{{brandInformation.translations.brand.actions.email}}
    </a></div>
</div>
