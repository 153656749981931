import * as tslib_1 from "tslib";
import { Type } from 'class-transformer';
import { AbstractMessage } from './abstract-message';
import { LatLng, SuggestedReply } from './suggestion';
import { Party } from './party';
import { serializeType } from './serialize-type';
export class ClientMessage extends AbstractMessage {
    constructor() {
        super();
        this.party = Party.CLIENT;
    }
    static clientTextMessage(id, text, timestamp = Date.now()) {
        const clientMessage = new ClientMessage();
        clientMessage.id = id;
        clientMessage.text = text;
        clientMessage.timestamp = timestamp;
        return clientMessage;
    }
    static clientMediaFileMessage(id, mediaFile, timestamp = Date.now()) {
        const clientMessage = new ClientMessage();
        clientMessage.id = id;
        clientMessage.mediaFile = mediaFile;
        clientMessage.timestamp = timestamp;
        return clientMessage;
    }
    static clientSuggestionResponseMessage(id, suggestionResponse, timestamp = Date.now()) {
        const clientMessage = new ClientMessage();
        clientMessage.id = id;
        clientMessage.suggestionResponse = suggestionResponse;
        clientMessage.timestamp = timestamp;
        return clientMessage;
    }
    static clientShareLocationMessage(id, location, timestamp = Date.now()) {
        const clientMessage = new ClientMessage();
        clientMessage.id = id;
        clientMessage.location = location;
        clientMessage.timestamp = timestamp;
        return clientMessage;
    }
    toString() {
        return `${this.constructor.name} (${super.toString()}, suggestionResponse: ${this.suggestionResponse})`;
    }
}
tslib_1.__decorate([
    Type(serializeType(LatLng)),
    tslib_1.__metadata("design:type", LatLng)
], ClientMessage.prototype, "location", void 0);
tslib_1.__decorate([
    Type(serializeType(SuggestedReply)),
    tslib_1.__metadata("design:type", SuggestedReply)
], ClientMessage.prototype, "suggestionResponse", void 0);
export class ClientMessageAck {
}
