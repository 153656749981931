import * as tslib_1 from "tslib";
import { Type } from 'class-transformer';
import { serializeType } from './serialize-type';
import { SuggestionChip } from './suggestion';
import { MediaFile } from './abstract-message';
export var CardOrientation;
(function (CardOrientation) {
    CardOrientation[CardOrientation["TOP"] = 0] = "TOP";
    CardOrientation[CardOrientation["LEFT"] = 1] = "LEFT";
    CardOrientation[CardOrientation["RIGHT"] = 2] = "RIGHT";
})(CardOrientation || (CardOrientation = {}));
export var CarouselCardWidth;
(function (CarouselCardWidth) {
    CarouselCardWidth[CarouselCardWidth["SMALL"] = 0] = "SMALL";
    CarouselCardWidth[CarouselCardWidth["MEDIUM"] = 1] = "MEDIUM";
})(CarouselCardWidth || (CarouselCardWidth = {}));
export class CardContent {
    toString() {
        return `(title: '${this.title}', mediaFile: '${this.mediaFile}', suggestions: ${this.suggestions}, description: '${this.description}')`;
    }
}
tslib_1.__decorate([
    Type(serializeType(MediaFile)),
    tslib_1.__metadata("design:type", MediaFile)
], CardContent.prototype, "mediaFile", void 0);
tslib_1.__decorate([
    Type(serializeType(SuggestionChip)),
    tslib_1.__metadata("design:type", Array)
], CardContent.prototype, "suggestions", void 0);
export class StandaloneCard {
    hasImage() {
        return this.content && this.content.mediaFile && this.content.mediaFile.isImage();
    }
    toString() {
        return `(content: '${this.content}', orientation: '${this.orientation}')`;
    }
}
tslib_1.__decorate([
    Type(serializeType(CardContent)),
    tslib_1.__metadata("design:type", CardContent)
], StandaloneCard.prototype, "content", void 0);
export class CarouselCard {
    toString() {
        return `(cards: '${this.cards}', width: '${this.width}')`;
    }
}
tslib_1.__decorate([
    Type(serializeType(CardContent)),
    tslib_1.__metadata("design:type", Array)
], CarouselCard.prototype, "cards", void 0);
