import { Injectable } from '@angular/core';
import { WindowRefService } from '../conversation/services/window-ref.service';
import { FileRepositoryService } from './file-repository.service';

@Injectable({
  providedIn: 'root'
})
export class FileCacheService {
  private _window: any;
  private cache = new Map<string, Promise<string>>();

  constructor(private fileRepositoryService: FileRepositoryService,
              private windowRefService: WindowRefService) {

    this._window = this.windowRefService.getWindowRef();
  }

  public getBlobUrlForFileId(id: string, mimeType: string): Promise<string> {

    let cacheEntry = this.cache.get(id);
    if (cacheEntry == null) {
      cacheEntry = new Promise( resolve => {

        this.fileRepositoryService.loadFile(id).then( (fileEntry) => {
          const url = this.createBlobUrl(fileEntry.data, mimeType);
          resolve(url);
        });

      });

      this.cache.set(id, cacheEntry);
    }

    return cacheEntry;
  }

  private createBlobUrl(data: Uint8Array, mimeType: string) {
    const blob: Blob = new Blob([data], {type: mimeType});
    return this._window.URL.createObjectURL(blob);
  }

  public destroy() {
    this.cache.forEach( (url) => {
      this._window.URL.revokeObjectURL(url);
    });
  }

}
