import { MediaFile } from '../../model/abstract-message';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { FileCacheService } from '../../../shared/file-cache.service';

export class SafeMediaUrlProvider {
  safeMediaUrl: Promise<SafeUrl>;
  safeMediaStyle: Promise<SafeStyle>;

  safeThumbnailUrl: Promise<SafeUrl>;
  safeThumbnailStyle: Promise<SafeStyle>;

  constructor(private mediaFile: MediaFile,
    private domSanitizer: DomSanitizer,
    private fileCacheService: FileCacheService) {

    this.initializeUrls();
  }

  private initializeUrls() {
    if (this.mediaFile.isAudio()) {
      throw new Error('Audio is not supported!');
    }

    if (this.mediaFile.thumbnailFilesId) {
      this.safeThumbnailUrl = this.getUrlPromise(this.mediaFile.thumbnailFilesId, this.mediaFile.thumbnailMimeType);
      this.safeThumbnailStyle = this.getStylePromise(this.mediaFile.thumbnailFilesId, this.mediaFile.thumbnailMimeType);
    } else if (this.mediaFile.thumbnailUrl) {
      this.safeThumbnailUrl = Promise.resolve(this.domSanitizer.bypassSecurityTrustUrl(this.mediaFile.thumbnailUrl));
      this.safeThumbnailStyle = Promise.resolve(this.getSafeStyle(this.mediaFile.thumbnailUrl));
    }

    if (this.mediaFile.filesId) {
      this.safeMediaUrl = this.getUrlPromise(this.mediaFile.filesId, this.mediaFile.mimeType);
      this.safeMediaStyle = this.getStylePromise(this.mediaFile.filesId, this.mediaFile.mimeType);
    } else if (this.mediaFile.fileUrl) {
      this.safeMediaUrl = Promise.resolve(this.domSanitizer.bypassSecurityTrustUrl(this.mediaFile.fileUrl));
      this.safeMediaStyle = Promise.resolve(this.getSafeStyle(this.mediaFile.fileUrl));
    }
  }

  private getSafeStyle(url: string): SafeStyle {
    return this.domSanitizer.bypassSecurityTrustStyle('url(' + url + ')');
  }

  private getUrlPromise(fileId: string, mimeType: string) {
    return new Promise<SafeUrl>( resolve => {
      this.fileCacheService.getBlobUrlForFileId(fileId, mimeType).then( (url) => {
        resolve(this.domSanitizer.bypassSecurityTrustUrl(url));
      });
    });
  }

  private getStylePromise(fileId: string, mimeType: string) {
    return new Promise<SafeStyle>( resolve => {
      this.fileCacheService.getBlobUrlForFileId(fileId, mimeType).then( (url) => {
        resolve(this.getSafeStyle(url));
      });
    });
  }

  public getMediaUrl() {
    return this.safeMediaUrl;
  }

  public getThumbnailUrl() {
    return this.safeThumbnailUrl;
  }

  public getMediaAsStyle() {
    return this.safeMediaStyle;
  }

  public getThumbnailAsStyle() {
    return this.safeThumbnailStyle;
  }

}
