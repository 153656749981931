import { environment } from '../../../../environments/environment';
const ɵ0 = (str) => {
    if (environment.webservice.debug) {
        console.log(str);
    }
};
export const stompConfig = {
    // Which server?
    brokerURL: environment.webservice.url,
    // Headers
    // Typical keys: login, passcode, host
    connectHeaders: {
        login: 'guest',
        passcode: 'guest'
    },
    // How often to heartbeat?
    // Interval in milliseconds, set to 0 to disable
    heartbeatIncoming: 30000,
    heartbeatOutgoing: 30000,
    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 5000 (5 seconds)
    reconnectDelay: 5000,
    // Will log diagnostics on console
    debug: ɵ0
};
export { ɵ0 };
