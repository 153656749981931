<app-top-bar [title]="'LINK Conversations'" [menu]="menu"></app-top-bar>

<mat-menu #menu="matMenu">
  <a href="https://www.linkmobility.com/contact/" target="_blank" mat-menu-item>
    <mat-icon>book</mat-icon>
    <span>Legal Disclosure</span>
  </a>
</mat-menu>

<div class="main">
  <div class="alert alert-danger not-found-alert" *ngIf="status === 'notfound'">
    <mat-icon>error_outline</mat-icon>
    <p><b>The requested conversation cannot be found!</b></p>
    <p>Please check if the link provided to you has errors in it.</p>
  </div>
  <ul class="conversation-list" *ngIf="conversations.length > 0">
    <li *ngFor="let conversation of conversations">
      <a class="convlink"  (click)="click(conversation.conversationHash)">
        <div class="logo" [ngStyle]="{'background-image': 'url(' + conversation.brandInfo.iconUrl + ')' }"></div>
        <div class="headline">{{conversation.brandInfo.headline}}</div>
      </a>
    </li>
  </ul>

  <h3 *ngIf="conversations.length == 0" class="status-message">No conversations found</h3>

</div>
