import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { ClientMessage } from '../model/client-message';
import { ImageService } from '../services/images/image.service';
import { MessageService } from '../services/message.service';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MediaFile } from '../model/abstract-message';
import { WindowRefService } from '../services/window-ref.service';
import { CustomerBrandInformation } from '../model/customer-brand-information';

@Component({
  selector: 'app-conversation-message-submit-form',
  templateUrl: './message-submit-form.component.html',
  styleUrls: ['./message-submit-form.component.css'],
})
export class MessageSubmitFormComponent implements OnInit, OnDestroy {

  private readonly MAX_FILE_SIZE = 10 * 1024 * 1024;
  private readonly MAX_LOCAL_IMAGE_WIDTH_HEIGHT = 1024;
  readonly SUPPORTED_MIMETYPES = [ 'image/gif', 'image/png', 'image/jpeg', 'image/jpg' ];

  @Input() conversationHash: string;
  @Input() brandInformation: CustomerBrandInformation;
  @Input() showCursor;
  @Output() onFocusEvent = new EventEmitter();


  private _window: any;
  previewImage: SafeUrl;
  private blobUrl;
  errorMessage: string = null;
  mediaFile: MediaFile;
  private fileConvertProgress: Promise<Uint8Array>;
  uploadProgressValue = null;

  private inputFile: File;

  @ViewChild('messageForm', { static: true }) messageForm: FormGroup;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild('msgInput', { static: true }) msgInput: ElementRef;
  @ViewChild('previewImageElement', { static: false }) previewImageElement: ElementRef;

  constructor(private messageService: MessageService,
              private windowRefService: WindowRefService,
              private domSanitizer: DomSanitizer,
              private imageService: ImageService) {

    this._window = this.windowRefService.getWindowRef();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.resetImage();
  }

  inputFocusEvent($event) {
    this.onFocusEvent.emit($event);
  }

  async submitMessage() {
    if (!this.isFormValid()) {
      return;
    }

    this.errorMessage = null;

    if (this.mediaFile) {
      // Send File
      const fileData = await this.fileConvertProgress;
      const mediaMsg = ClientMessage.clientMediaFileMessage(UUID.UUID(), this.mediaFile);

      this.uploadProgressValue = 0;
      try {
        await this.messageService.sendMediaClientMessage(this.conversationHash, mediaMsg, fileData, fileData, percent => this.uploadProgressValue = percent);
      } catch (e) {
        console.error(e);
        this.errorMessage = this.brandInformation ? this.brandInformation.translations['conversation']['sendMessage']['errorFileUploadFailed'] : 'Upload failed! Please try again.';
      }
      this.uploadProgressValue = null;
    }

    // Send Text Message
    this.sendTextMessage();
    this.messageForm.reset();
    this.cancelImage();
  }

  inputMouseMove(event) {
    if (event.cancelable) {
      event.preventDefault();
    }
    event.stopPropagation();
  }

  private sendTextMessage() {
    if (this.msgInput.nativeElement.value.length > 0) {
      const msg = ClientMessage.clientTextMessage(UUID.UUID(), this.msgInput.nativeElement.value);
      this.messageService.sendTextClientMessage(this.conversationHash, msg);
    }
  }

  get isConnected(): boolean {
    return this.messageService.isConnected();
  }

  get isFormDisabled(): boolean {
    return !this.isConnected || this.uploadProgressValue != null;
  }

  isFormValid(): boolean {
    return !!this.mediaFile || this.msgInput.nativeElement.value.length > 0;
  }

  selectImage() {
    this.fileInput.nativeElement.click();
  }

  onFileChange(event: any) {
    this.resetImage();

    const file: File = event.target.files[0];

    if (!this.isFileTypeCorrect(file)) {
      this.errorMessage = this.brandInformation ? this.brandInformation.translations['conversation']['sendMessage']['errorFileTypeNotSupported'] : 'File type is not supported.';
      this.cancelImage();
      return;
    } else if (!this.isFileSizeCorrect(file)) {
      this.errorMessage = this.brandInformation ? this.brandInformation.translations['conversation']['sendMessage']['errorFileSizeTooBig'] : 'File size is too big.';
      this.cancelImage();
      return;
    } else {
      this.errorMessage = null;
    }

    const mediaFile: MediaFile = new MediaFile();
    mediaFile.mimeType = file.type;
    mediaFile.contentLength = file.size;
    this.mediaFile = mediaFile;

    // Show Preview
    this.registerBlobUrl(file);
    this.previewImage = this.domSanitizer.bypassSecurityTrustUrl(this.blobUrl);

    // Convert File to Array
    this.fileConvertProgress = this.imageService.fileToArray(file);
  }

  cancelImage() {
    this.resetImage();
    this.fileInput.nativeElement.value = '';
  }

  private resetImage() {
    if (this.previewImage) {
      this.previewImage = null;
    }
    this.unregisterBlobUrl();
    this.mediaFile = null;
  }

  private isFileTypeCorrect(file: File): boolean {
    return file && file.type && this.SUPPORTED_MIMETYPES.some(t => t === file.type);
  }

  private isFileSizeCorrect(file: File): boolean {
    return file && file.size <= this.MAX_FILE_SIZE;
  }

  private unregisterBlobUrl() {
    if (this.blobUrl) {
      this._window.URL.revokeObjectURL(this.blobUrl);
      this.blobUrl = null;
    }
  }

  private registerBlobUrl(file: File) {
    this.unregisterBlobUrl();
    this.blobUrl = this._window.URL.createObjectURL(file);
    return this.blobUrl;
  }

}
