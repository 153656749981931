import { Component, Input } from '@angular/core';
import { CustomerBrandInformation } from '../model/customer-brand-information';

@Component({
  selector: 'app-brand-header',
  templateUrl: './brand-header.component.html',
  styleUrls: ['./brand-header.component.css']
})
export class BrandHeaderComponent {

  @Input() brandInformation: CustomerBrandInformation = null;
  @Input() hasMessages = false;

}
