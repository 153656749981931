import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CardContent, CardOrientation, CarouselCardWidth } from '../../model/cards';
import { ImageOverlayService } from '../../../shared/image-overlay.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SafeMediaUrlProvider } from '../../services/images/safe-media-url-provider';
import { SafeMediaUrlProviderFactory } from '../../services/images/safe-media-url-provider-factory.service';

@Component({
  selector: 'app-card-content',
  templateUrl: './card-content.component.html',
  styleUrls: ['./card-content.component.css']
})
export class CardContentComponent implements OnInit {

  private static readonly PERCENT: number = 50;

  @Input() cardContent: CardContent;
  @Input() cardOrientation: CardOrientation;
  @Input() cardWidth: CarouselCardWidth;
  @Input() messageId: string;

  @ViewChild('videoPlayer', { static: true }) videoPlayer: any;

  isPlaying: boolean;
  mediaUrlProvider: SafeMediaUrlProvider;

  private arabicExp: RegExp;

  constructor(private imageOverlayService: ImageOverlayService,
              private mediaUrlProviderFactory: SafeMediaUrlProviderFactory,
              private sanitizer: DomSanitizer) {
    this.arabicExp = new RegExp('^[\u0600-\u06FF]');
  }

  ngOnInit() {
    if (this.hasMedia()) {
      this.mediaUrlProvider = this.mediaUrlProviderFactory.createProvider(this.cardContent.mediaFile);
    }
  }

  public getOrientation(): string {
    if (this.cardOrientation) {
      return this.cardOrientation.toString().toLowerCase();
    }
    return 'top';
  }

  openImageOverlay() {
    this.mediaUrlProvider.getMediaUrl().then( (url) => {
      this.imageOverlayService.open(url);
    });
  }

  isVideo(): boolean {
    return this.hasMedia() && this.cardContent.mediaFile.isVideo();
  }

  showImage(): boolean {
    return this.hasMedia() && this.cardContent.mediaFile.isImage();
  }

  showVideoThumbnail(): boolean {
    return this.isVideo() && !this.isPlaying;
  }

  hasThumbnail(): boolean {
    return this.cardContent.mediaFile.hasThumbnail();
  }

  hasMedia(): boolean {
    return !!this.cardContent && !!this.cardContent.mediaFile;
  }

  getMediaUrl(): string {
    if (this.hasMedia()) {
      return this.cardContent.mediaFile.fileUrl;
    }
    return undefined;
  }

  getMediaMimeType(): string {
    if (this.hasMedia()) {
      return this.cardContent.mediaFile.mimeType;
    }
    return undefined;
  }

  public getThumbnailAsStyle(): Promise<SafeStyle> {
    if (this.mediaUrlProvider) {
      return this.mediaUrlProvider.getThumbnailAsStyle();
    }
    return Promise.resolve(undefined);
  }

  getImageAsStyle(): Promise<SafeStyle> {
    if (this.mediaUrlProvider) {
      return this.mediaUrlProvider.getMediaAsStyle();
    }
    return Promise.resolve(undefined);
  }

  onThumbnailClicked() {
    if (this.videoPlayer) {
      this.isPlaying = true;
      this.videoPlayer.nativeElement.play();
    }
  }

  hideVideoElement(): boolean {
    return !this.isVideo() || !this.isPlaying;
  }

  onEnded(): void {
    this.isPlaying = false;
  }

  hasSuggestions(): boolean {
    return (this.cardContent && this.cardContent.suggestions && this.cardContent.suggestions.length > 0);
  }

  getThumbnailOrImageAsStyle(): Promise<SafeStyle> {
    return this.hasThumbnail() ? this.getThumbnailAsStyle() : this.getImageAsStyle();
  }

  private isPercentageArabic(text: string): boolean {
    let count = 0;
    if (text) {
      for (let i = 0; i < text.length; i++) {
        if (this.arabicExp.test(text[i])) {
          count++;
        }
      }
      return count >= ((text.length * CardContentComponent.PERCENT) / 100);
    }
    return false;
  }

  public isPercentageArabicTitle(): boolean {
    if (this.cardContent && this.cardContent.title) {
      return this.isPercentageArabic(this.cardContent.title.toString());
    }
    return false;
  }

  public isPercentageArabicDescription(): boolean {
    if (this.cardContent && this.cardContent.description) {
      return this.isPercentageArabic(this.cardContent.description.toString());
    }
    return false;
  }
}
