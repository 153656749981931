import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LatLng } from '../model/suggestion';
import { environment } from '../../../environments/environment';

@Injectable()
export class StaticMapService {

  readonly STATIC_MAP_URL: string = 'https://maps.googleapis.com/maps/api/staticmap';
  readonly ZOOM: string = '15';
  readonly SCALE: string = '2';
  readonly SIZE: string = '300x150';

  constructor(private http: HttpClient) {
  }

  public getMapImage(location: LatLng): Promise<Blob> {
    const mapUrl: string = this.STATIC_MAP_URL;
    const params = new HttpParams({
      fromObject: {
        zoom: this.ZOOM,
        scale: this.SCALE,
        size: this.SIZE,
        markers: 'color:red|label:A|' + location.latitude + ',' + location.longitude,
        style: 'feature:poi|visibility:off',
        key: environment.googlemaps.apikey
      }
    });
    const httpClient = this.http;
    return new Promise(function (resolve, reject) {
      httpClient.get(mapUrl, {responseType: 'blob', params: params}).subscribe(data => {
        resolve(data);
      }, err => {
        console.log('Error getting static map image: ' + err.error);
        reject(err);
      });
    });
  }
}
