import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PersistentImage } from '../../model/abstract-message';

@Injectable()
export class ImageService {

  constructor(private http: HttpClient) {
  }

  public downloadFileAsBlob(fileUrl: string): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) =>
      this.http.get(fileUrl, {responseType: 'blob'})
        .subscribe(data => resolve(data), err => {
          console.log('Error downloading image: ' + err.error);
          reject(err);
        }));
  }

  public blobToArray(data: Blob): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const fr: FileReader = new FileReader();
      fr.onload = (event: any) => {
        resolve(new Uint8Array(event.target.result));
      };
      fr.onerror = (reason) => {
        fr.abort();
        reject(reason);
      };
      fr.readAsArrayBuffer(data);
    });
  }

  public fileToArray(file: File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = (event: any) => {
        resolve(new Uint8Array(event.target.result));
      };
      fr.onerror = () => {
        fr.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      fr.readAsArrayBuffer(file);
    });
  }

  /** @deprecated Used ony for DB upgrade process */
  public downloadImage(imageUrl: string): Promise<PersistentImage> {
    return new Promise<PersistentImage>((resolve, reject) =>
      this.http.get(imageUrl, {responseType: 'blob'})
        .subscribe(data => {
          this.blobToPersistentImage(data)
            .then(resolved => resolve(resolved))
            .catch(reason => reject(reason));
        }, err => {
          console.log('Error downloading image: ' + err.error);
          reject(err);
        }));
  }

  /** @deprecated Used ony for DB upgrade process */
  public blobToPersistentImage(data: Blob): Promise<PersistentImage> {
    return new Promise((resolve, reject) => {
      const fr: FileReader = new FileReader();
      fr.onload = (event: any) => {
        const persistentImage = new PersistentImage();
        persistentImage.type = data.type;
        persistentImage.data = Array.prototype.slice.call(new Uint8Array(event.target.result));
        resolve(persistentImage);
      };
      fr.onerror = (reason) => {
        reject(reason);
      };
      fr.readAsArrayBuffer(data);
    });
  }

  public resizeImage(image: HTMLImageElement, mimeType: string, maxWidth: number, maxHeight: number): Promise<Uint8Array> {
    const canvas = document.createElement('canvas');

    // Calculate the new width and height of the image
    let width = image.width;
    let height = image.height;
    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
    }

    // Draw the image with the calculated width and height
    canvas.width = width;
    canvas.height = height;
    const ctxResized = canvas.getContext('2d');
    ctxResized.drawImage(image, 0, 0, width, height);

    // Convert the (resized) image to a PersistentImage
    return new Promise<Uint8Array>(resolve => {
      canvas.toBlob((result: Blob) => resolve(this.blobToArray(result)), mimeType);
    });
  }
}
