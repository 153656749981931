import { Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UpdateService } from './shared/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent {

  public constructor(private titleService: Title, private updateService: UpdateService) {
    this.titleService.setTitle('LINK Conversations');
    this.updateService.checkForUpdate();
  }
}
