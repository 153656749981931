import { environment as defaultEnvironment } from './environment.defaults';

export const environment = {
  ...defaultEnvironment,
  production: true,
  webservice: {
    url: 'wss://web-conversation.dev.conversationalmessaging.com/web-conversation-public/conversation',
    imageUrl: 'https://web-conversation.dev.conversationalmessaging.com/web-conversation-public/internal/v1/user-media-file',
    debug: false
  },
  pushPublicKey: 'BOEHa7U9MXy8fL9hUZZC0jjaVokDmpJE02oll1ck7TsZCSyr9v1FgvtRyAUuYDcPZFw5IELIHjYAMeGEVTtfJoA',
  googlemaps: {
    url: 'https://maps.google.com/?q=',
    apikey: 'AIzaSyBic1DC-gsCL7rXm32WKjBMdWLgONQWNrc'
  }
};
