import { Injectable } from '@angular/core';
import { Message } from '@stomp/stompjs';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { AgentMessage } from '../../model/agent-message';
import { ClientMessage } from '../../model/client-message';
import { MessageMapper } from '../../model/message-mapper';
import { CustomerBrandInformation } from '../../model/customer-brand-information';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { RxStompService } from './rx-stomp.service';


@Injectable()
export class ConversationClientService {

  private agentMessagesObservable: Observable<Message>;
  // subscription for stream of messages
  private agentMessagesSubscription: Subscription;
  private clientMessagesAckSubscription: Subscription;

  private cache = new Map<string, Promise<CustomerBrandInformation>>();

  constructor(private stompService: RxStompService, private http: HttpClient) {
  }

  public subscribeForAgentMessages(conversationHash: string, callbackFunction: (value: string, ConversationMessage) => void): Subscription {
    this.validateMessageSubscription();
    // Stream of messages
    this.agentMessagesObservable = this.stompService.watch(`/queue/conversation/${conversationHash}`);
    // Subscribe a function to be run on received message
    this.agentMessagesSubscription = this.agentMessagesObservable.subscribe(stompMessage => {
      const conversationMessage = MessageMapper.agentMessageFromJson(stompMessage.body);
      callbackFunction(conversationHash, conversationMessage);
      this.acknowledgeReceivedMessage(conversationHash, conversationMessage);
    });
    return this.agentMessagesSubscription;
  }

  public subscribeForClientMessageAcks(conversationHash: string, callbackFunction: (value: string, ConversationMessage) => void): Subscription {
    // Stream of messages
    const observable = this.stompService.watch(`/queue/conversation/${conversationHash}/ack`);
    // Subscribe a function to be run on received message
    this.clientMessagesAckSubscription = observable.subscribe(stompMessage => {
      const conversationMessage = MessageMapper.clientMessageAckFromJson(stompMessage.body);
      callbackFunction(conversationHash, conversationMessage);
    });
    return this.clientMessagesAckSubscription;
  }

  private validateMessageSubscription() {
    if (this.agentMessagesSubscription != null) {
      throw new Error('Subscription already exists - unsubscribe first');
    }
  }

  private acknowledgeReceivedMessage(conversationHash: string, agentMessage: AgentMessage) {
    this.stompService.publish({destination: `/app/web-conversation-public/conversation/${conversationHash}/ack/${agentMessage.id}`, body: 'true'});
  }

  public sendClientMessage(conversationHash: string, clientMessage: ClientMessage) {
    this.stompService.publish({destination: `/app/web-conversation-public/conversation/${conversationHash}`, body: MessageMapper.toJson(clientMessage)});
  }

  public sendPushSubscription(conversationHash: string, subscription: string) {
    this.stompService.publish({destination: `/app/web-conversation-public/conversation/${conversationHash}/subscribed`, body: subscription});
  }

  public verifyPushSubscription(conversationHash: string, subscription: string) {
    this.stompService.publish({destination: `/app/web-conversation-public/conversation/${conversationHash}/verifysubscription`, body: subscription});
  }

  public sendUnsubscribedForPush(conversationHash: string) {
    this.stompService.publish({destination: `/app/web-conversation-public/conversation/${conversationHash}/unsubscribe`, body: 'stop'});
  }

  public unsubscribeFromAgentMessage() {
    // This will internally unsubscribe from Stomp Broker. There are two subscriptions - one created explicitly, the other created in the template by use of 'async'
    if (this.agentMessagesSubscription != null) {
      this.agentMessagesSubscription.unsubscribe();
      this.agentMessagesSubscription = null;
      this.agentMessagesObservable = null;
    }
  }

  public unsubscribeFromClientMessageAck() {
    // This will internally unsubscribe from Stomp Broker. There are two subscriptions - one created explicitly, the other created in the template by use of 'async'
    if (this.clientMessagesAckSubscription != null) {
      this.clientMessagesAckSubscription.unsubscribe();
      this.clientMessagesAckSubscription = null;
    }
  }

  public getBrandInformation(conversationHash: string): Promise<CustomerBrandInformation> {
    if (this.cache.get(conversationHash) == null) {
      this.cache.set(conversationHash, new Promise<CustomerBrandInformation>((resolve, reject) => {
        const brandInfoJson = localStorage.getItem('brand_' + conversationHash);
        if (brandInfoJson) {
          const agentInfo = this.parseBrandInformation(brandInfoJson);
          resolve(agentInfo);
        }
        this.stompService.watch(`/queue/customer/${conversationHash}`).subscribe(stompMessage => {
          console.info(`Received STOMP CustomerBrandInformation ${stompMessage}`);
          const agentInfo = this.parseBrandInformation(stompMessage.body);

          if (agentInfo.headline) {
            localStorage.setItem('brand_' + conversationHash, stompMessage.body);
            resolve(agentInfo);
          } else {
            reject('Conversation not found');
          }
        });
      }));
    }

    return this.cache.get(conversationHash);
  }

  private parseBrandInformation(brandInfoJson: string): CustomerBrandInformation {
    const agentInfo = CustomerBrandInformation.fromJson(brandInfoJson);
    agentInfo.translations = this.getTranslations(agentInfo.locale);

    return agentInfo;
  }

  private getTranslations(locale: string): Map<string, object> {
    let translations = locale ? environment.translations[locale] : undefined;
    if (!translations) {
      translations = environment.translations.en;
    }
    return translations;
  }

  public isConnected(): boolean {
    return this.stompService.connected();
  }

  public uploadImageMessage(conversationHash: string, message: ClientMessage, fileData: Uint8Array, progressHandler?: (percent: number) => void) {
    return new Promise<Uint8Array>((resolve, reject) => {
      const formData = new FormData();
      formData.append('message', new Blob(
        [MessageMapper.toJson(ClientMessage.clientTextMessage(message.id, null))],
        {type: 'application/json'}));
      formData.append('file', new Blob([fileData], {type: message.mediaFile.mimeType}));

      this.http.post(`${environment.webservice.imageUrl}/${conversationHash}`, formData, {
        reportProgress: true,
        observe: 'events',
        responseType: 'arraybuffer'
      }).subscribe((event: any) => {
          if (progressHandler != null && event.type === HttpEventType.UploadProgress) {
            progressHandler(Math.round(100 * event.loaded / event.total));
          } else if (event instanceof HttpResponse) {
            console.info('Image upload complete!');
            resolve(new Uint8Array(event.body));
          }
        },
        error => reject(error));
    });
  }
}
