import { Injectable } from '@angular/core';

import { ConversationClientService } from './stomp/conversation-client.service';
import { CustomerBrandInformation } from '../model/customer-brand-information';

@Injectable()
export class BrandInformationService {

  constructor(private conversationClient: ConversationClientService) {
  }

  public getBrandInformation(conversationHash: string): Promise<CustomerBrandInformation> {
    return this.conversationClient.getBrandInformation(conversationHash);
  }

}
