/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carousel.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../card-content/card-content.component.ngfactory";
import * as i3 from "../card-content/card-content.component";
import * as i4 from "../../../shared/image-overlay.service";
import * as i5 from "../../services/images/safe-media-url-provider-factory.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./carousel.component";
var styles_CarouselComponent = [i0.styles];
var RenderType_CarouselComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarouselComponent, data: {} });
export { RenderType_CarouselComponent as RenderType_CarouselComponent };
function View_CarouselComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-content", [], null, null, null, i2.View_CardContentComponent_0, i2.RenderType_CardContentComponent)), i1.ɵdid(1, 114688, null, 0, i3.CardContentComponent, [i4.ImageOverlayService, i5.SafeMediaUrlProviderFactory, i6.DomSanitizer], { cardContent: [0, "cardContent"], cardWidth: [1, "cardWidth"], messageId: [2, "messageId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.message.carouselCard.width.toString().toLowerCase(); var currVal_2 = _co.message.id; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CarouselComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "scroll-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselComponent_1)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message.carouselCard.cards; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CarouselComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel", [], null, null, null, View_CarouselComponent_0, RenderType_CarouselComponent)), i1.ɵdid(1, 114688, null, 0, i8.CarouselComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarouselComponentNgFactory = i1.ɵccf("app-carousel", i8.CarouselComponent, View_CarouselComponent_Host_0, { message: "message" }, {}, []);
export { CarouselComponentNgFactory as CarouselComponentNgFactory };
