/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./information-page.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./brand-images/brand-images.component.ngfactory";
import * as i3 from "./brand-images/brand-images.component";
import * as i4 from "./actions/actions.component.ngfactory";
import * as i5 from "./actions/actions.component";
import * as i6 from "./details/details.component.ngfactory";
import * as i7 from "./details/details.component";
import * as i8 from "../shared/top-bar/top-bar.component.ngfactory";
import * as i9 from "../shared/top-bar/top-bar.component";
import * as i10 from "../conversation/services/stomp/conversation-client.service";
import * as i11 from "@angular/router";
import * as i12 from "@angular/common";
import * as i13 from "./information-page.component";
import * as i14 from "../conversation/services/brand-information.service";
var styles_InformationPageComponent = [i0.styles];
var RenderType_InformationPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InformationPageComponent, data: {} });
export { RenderType_InformationPageComponent as RenderType_InformationPageComponent };
function View_InformationPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-brand-images", [], null, null, null, i2.View_BrandImagesComponent_0, i2.RenderType_BrandImagesComponent)), i1.ɵdid(2, 114688, null, 0, i3.BrandImagesComponent, [], { brandInformation: [0, "brandInformation"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-actions", [], null, null, null, i4.View_ActionsComponent_0, i4.RenderType_ActionsComponent)), i1.ɵdid(4, 114688, null, 0, i5.ActionsComponent, [], { brandInformation: [0, "brandInformation"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-details", [], null, null, null, i6.View_DetailsComponent_0, i6.RenderType_DetailsComponent)), i1.ɵdid(6, 114688, null, 0, i7.DetailsComponent, [], { brandInformation: [0, "brandInformation"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.brandInformation; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.brandInformation; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.brandInformation; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_InformationPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-top-bar", [], null, null, null, i8.View_TopBarComponent_0, i8.RenderType_TopBarComponent)), i1.ɵdid(1, 49152, null, 0, i9.TopBarComponent, [i10.ConversationClientService, i1.NgZone, i11.Router], { backLink: [0, "backLink"], title: [1, "title"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InformationPageComponent_1)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, "/conversation", _co.conversationHash); var currVal_1 = (_co.brandInformation ? _co.brandInformation.translations.brand.informationHeadline : "Information"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.brandInformation; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_InformationPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-information-page", [], null, null, null, View_InformationPageComponent_0, RenderType_InformationPageComponent)), i1.ɵdid(1, 114688, null, 0, i13.InformationPageComponent, [i11.ActivatedRoute, i14.BrandInformationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InformationPageComponentNgFactory = i1.ɵccf("app-information-page", i13.InformationPageComponent, View_InformationPageComponent_Host_0, {}, {}, []);
export { InformationPageComponentNgFactory as InformationPageComponentNgFactory };
