import { Component, Input, OnInit } from '@angular/core';
import { CustomerBrandInformation } from '../../conversation/model/customer-brand-information';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  @Input() brandInformation: CustomerBrandInformation = new CustomerBrandInformation();

  constructor() { }

  ngOnInit() {
  }

}
