
export class ImageBase64Utils {
  // TODO(pasz): 20.06.2018 Check if there is a more optimized version available
  public static imageBytesToBase64(bytes: number[]): string {
    if (bytes == null) {
      return;
    }
    return btoa(bytes.map(
      b => String.fromCharCode(b)
      ).join('')
    );
  }

  public static base64ToImageBytes(value: string): number[] {
    const decodedString = atob(value);
    const decodedBytes: number[] = [];
    for (let i = 0, l = decodedString.length; i < l; i++) {
      decodedBytes[i] = decodedString.charCodeAt(i);
    }
    return decodedBytes;
  }
}
