/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-audio.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./media-audio.component";
var styles_MediaAudioComponent = [i0.styles];
var RenderType_MediaAudioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaAudioComponent, data: {} });
export { RenderType_MediaAudioComponent as RenderType_MediaAudioComponent };
export function View_MediaAudioComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "audio"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "audio", [["controls", ""], ["preload", "none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [], [[8, "src", 4], [8, "type", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDescription(); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.message.mediaFile.fileUrl; var currVal_2 = _co.message.mediaFile.mimeType; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = (_co.brandInformation ? _co.brandInformation.translations.conversation.messages.errorHtml5AudioNotSupported : "Unfortunately, your browser does not support HTML5 audio."); _ck(_v, 3, 0, currVal_3); }); }
export function View_MediaAudioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-conversation-message-audio", [], null, null, null, View_MediaAudioComponent_0, RenderType_MediaAudioComponent)), i1.ɵdid(1, 49152, null, 0, i2.MediaAudioComponent, [], null, null)], null, null); }
var MediaAudioComponentNgFactory = i1.ɵccf("app-conversation-message-audio", i2.MediaAudioComponent, View_MediaAudioComponent_Host_0, { message: "message", brandInformation: "brandInformation" }, {}, []);
export { MediaAudioComponentNgFactory as MediaAudioComponentNgFactory };
