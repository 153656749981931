import { Component, HostListener, AfterViewInit, AfterViewChecked, Input, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { CreateCalendarEventAction, DialAction, LatLng, OpenUrlAction, SuggestedAction, SuggestionChip, ViewLocationAction } from '../../../model/suggestion';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../services/message.service';
import { MapLocationPickerService } from '../../../../shared/map-location-picker.service';
import { IcalService } from '../../../services/ical.service';
import { BrandInformationService } from '../../../services/brand-information.service';
import { WindowRefService } from '../../../services/window-ref.service';
import { StaticMapService } from '../../../services/static-map.service';
import { HttpService } from '../../../services/http-service';
import { UUID } from 'angular2-uuid';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-vanishing-suggestion',
  templateUrl: './vanishing-suggestion.component.html',
  styleUrls: ['./vanishing-suggestion.component.css']
})
export class VanishingSuggestionComponent implements AfterViewInit, OnInit, AfterViewChecked {


  readonly _window: Window;

  @Input() messageId: string;
  @Input() suggestions: Array<SuggestionChip>;
  conversationHash: string;
  showSuggestion = true;

  constructor(private route: ActivatedRoute,
              private messageService: MessageService,
              private mapLocation: MapLocationPickerService,
              private icalService: IcalService,
              private brandInfoService: BrandInformationService,
              private windowRefService: WindowRefService,
              private staticMapService: StaticMapService,
              private httpService: HttpService) {

        this._window = this.windowRefService.getWindowRef();
  }

  ngAfterViewInit() {
  }

  public getId(index): string {
    return this.messageId + '_' + index;
  }

  ngOnInit() {
    this.conversationHash = this.route.snapshot.paramMap.get('hash');
  }

  ngAfterViewChecked() {
  }

  @HostListener('keydown', ['$event'])
  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('mousedown', ['$event'])
  @HostListener('click', ['$event'])
  @HostListener('mouseup', ['$event'])
  onWindowEvent(event): any {
     return null;
  }

  public click(suggestion: SuggestionChip): void {
    if (!this.showSuggestion) {
      return;
    }
      if (this.isConnected()) {
        if (suggestion.isReply()) {
          this.messageService.sendSuggestionResponseClientMessage(this.conversationHash, UUID.UUID(), Date.now(), suggestion.reply);
          this.showSuggestion = false;
        } else {
          this.suggestedActionOnClick(suggestion.action);
        }
      }
  }

  suggestedActionOnClick(suggestion: SuggestedAction): void {
    this.messageService.sendSuggestionClickedEventMessage(this.conversationHash, UUID.UUID(), Date.now(), suggestion);
    if (!isNullOrUndefined(suggestion.dialAction)) {
      this.dialActionOnClick(suggestion.dialAction);
    } else if (!isNullOrUndefined(suggestion.openUrlAction)) {
      this.openUrlActionOnClick(suggestion.openUrlAction);
    } else if (!isNullOrUndefined(suggestion.shareLocationAction)) {
      this.shareLocationActionOnClick();
    } else if (!isNullOrUndefined(suggestion.viewLocationAction)) {
      this.viewLocationActionOnClick(suggestion.viewLocationAction);
    } else if (!isNullOrUndefined(suggestion.createCalendarEventAction)) {
      this.createCalendarEventActionOnClick(suggestion.createCalendarEventAction);
    }
  }

  public isConnected(): boolean {
    return this.messageService.isConnected();
  }

  private dialActionOnClick(action: DialAction): void {
    this._window.location.href = 'tel:' + action.phoneNumber;
  }

  private openUrlActionOnClick(action: OpenUrlAction): void {
    if (!action.request) {
    this._window.open(action.url, '_blank');
    } else {
      const win: Window = this._window.open('', '_blank');
      this.httpService.getHttp().post(action.url, null, {responseType: 'text'  as 'json'})
      .subscribe(
        data => {
         action.url = data.toString();
         if (win) {
          win.location.href = action.url;
         }
         action.request = false;
         this.messageService.updateOpenUrlMessage(this.messageId, this.conversationHash);
        },
        error => {
          if (win) {
            win.close();
          }
        },
        () => {
        });
      }
  }

  private shareLocationActionOnClick(): void {
    this.brandInfoService.getBrandInformation(this.conversationHash).then(brandInformation => {
      // Workaround to fix visibility of Googles Search Result box
      window.scrollTo(0, 0);
      this.mapLocation.open(brandInformation, this.shareLocationActionCallback);
    });
  }

  shareLocationActionCallback = (location: LatLng, address: string) => {
    this.handleShareLocationAction(location, address);
  };

  private viewLocationActionOnClick(action: ViewLocationAction): void {
    if (!isNullOrUndefined(action.latLong)) {
      // TODO label is ignored here. should be appended to URL
      this._window.open(environment.googlemaps.url + action.latLong.latitude + ',' + action.latLong.longitude);
    } else if (action.query) {
      this._window.open(environment.googlemaps.url + action.query);
    }
  }

  private createCalendarEventActionOnClick(action: CreateCalendarEventAction): void {
    this.brandInfoService.getBrandInformation(this.conversationHash).then(brandInformation => {
      this._window.location.href = this.icalService.genertateDataLinkForIcs(action, brandInformation);
    });
  }

  public mouseDown(event): any {

  }


  trackBySuggestionId(index: number, suggestion: SuggestionChip) {
     return index;
  }

  protected handleShareLocationAction(location: LatLng, address: string): void {
    if (!isNullOrUndefined(location)) {
      const messageId: string = UUID.UUID();
      this.messageService.sendShareLocationClientMessage(this.conversationHash, messageId, Date.now(), location, address);
      this.staticMapService.getMapImage(location).then(res => {
        this.messageService.updateStaticMapImageMessage(this.conversationHash, messageId, res);
      });
    }
    // Workaround to fix visibility of Googles Search Result box
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    if (!isNullOrUndefined(location)) {
      this.showSuggestion = false;
    }
  }
}
