/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-location-picker.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@agm/core/directives/map.ngfactory";
import * as i8 from "@agm/core/services/managers/info-window-manager";
import * as i9 from "@agm/core/services/google-maps-api-wrapper";
import * as i10 from "@agm/core/services/managers/marker-manager";
import * as i11 from "@agm/core/services/managers/circle-manager";
import * as i12 from "@agm/core/services/managers/rectangle-manager";
import * as i13 from "@agm/core/services/managers/polyline-manager";
import * as i14 from "@agm/core/services/managers/polygon-manager";
import * as i15 from "@agm/core/services/managers/kml-layer-manager";
import * as i16 from "@agm/core/services/managers/data-layer-manager";
import * as i17 from "@agm/core/services/maps-api-loader/maps-api-loader";
import * as i18 from "@agm/core/services/fit-bounds";
import * as i19 from "@agm/core/directives/map";
import * as i20 from "@agm/core/directives/marker";
import * as i21 from "@angular/forms";
import * as i22 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i23 from "@angular/material/button";
import * as i24 from "@angular/cdk/a11y";
import * as i25 from "./map-location-picker.component";
import * as i26 from "@angular/cdk/overlay";
var styles_MapLocationPickerComponent = [i0.styles];
var RenderType_MapLocationPickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapLocationPickerComponent, data: {} });
export { RenderType_MapLocationPickerComponent as RenderType_MapLocationPickerComponent };
function View_MapLocationPickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["id", "detectingPosition"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_3 = 24; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.brandInformation.translations.conversation.shareLocation.detectingPosition; _ck(_v, 4, 0, currVal_4); }); }
export function View_MapLocationPickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { searchElementRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "agm-map", [], [[2, "sebm-google-map-container", null]], [[null, "mapReady"], [null, "mapClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mapReady" === en)) {
        var pd_0 = (_co.mapReady($event) !== false);
        ad = (pd_0 && ad);
    } if (("mapClick" === en)) {
        var pd_1 = (_co.placeMarker($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_AgmMap_0, i7.RenderType_AgmMap)), i1.ɵprd(4608, null, i8.InfoWindowManager, i8.InfoWindowManager, [i9.GoogleMapsAPIWrapper, i1.NgZone, i10.MarkerManager]), i1.ɵprd(4608, null, i11.CircleManager, i11.CircleManager, [i9.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i12.RectangleManager, i12.RectangleManager, [i9.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i13.PolylineManager, i13.PolylineManager, [i9.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i14.PolygonManager, i14.PolygonManager, [i9.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i15.KmlLayerManager, i15.KmlLayerManager, [i9.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i16.DataLayerManager, i16.DataLayerManager, [i9.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(512, null, i9.GoogleMapsAPIWrapper, i9.GoogleMapsAPIWrapper, [i17.MapsAPILoader, i1.NgZone]), i1.ɵprd(512, null, i18.FitBoundsService, i18.FitBoundsService, [i17.MapsAPILoader]), i1.ɵdid(11, 770048, null, 0, i19.AgmMap, [i1.ElementRef, i9.GoogleMapsAPIWrapper, i18.FitBoundsService], { longitude: [0, "longitude"], latitude: [1, "latitude"], zoom: [2, "zoom"], streetViewControl: [3, "streetViewControl"] }, { mapClick: "mapClick", mapReady: "mapReady" }), i1.ɵprd(512, null, i10.MarkerManager, i10.MarkerManager, [i9.GoogleMapsAPIWrapper, i1.NgZone]), (_l()(), i1.ɵeld(13, 0, null, 0, 3, "agm-marker", [["id", "location"]], null, [[null, "dragEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragEnd" === en)) {
        var pd_0 = (_co.placeMarker($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i18.FitBoundsAccessor, null, [i20.AgmMarker]), i1.ɵdid(15, 1720320, null, 1, i20.AgmMarker, [i10.MarkerManager], { latitude: [0, "latitude"], longitude: [1, "longitude"], draggable: [2, "draggable"] }, { dragEnd: "dragEnd" }), i1.ɵqud(603979776, 2, { infoWindow: 1 }), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "form-group"], ["id", "bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, [[1, 0], ["search", 1]], null, 5, "input", [["autocapitalize", "off"], ["autocorrect", "off"], ["class", "pac controls"], ["id", "pac-input"], ["spellcheck", "off"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 19)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 19)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, null, 0, i21.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i21.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i21.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i21.DefaultValueAccessor]), i1.ɵdid(21, 540672, null, 0, i21.FormControlDirective, [[8, null], [8, null], [6, i21.NG_VALUE_ACCESSOR], [2, i21.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i21.NgControl, null, [i21.FormControlDirective]), i1.ɵdid(23, 16384, null, 0, i21.NgControlStatus, [[4, i21.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapLocationPickerComponent_1)), i1.ɵdid(25, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 6, "div", [["id", "bottom-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 2, "button", [["class", "pac controls"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyLocation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i22.View_MatButton_0, i22.RenderType_MatButton)), i1.ɵdid(28, 180224, null, 0, i23.MatButton, [i1.ElementRef, i24.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(29, 0, ["", ""])), (_l()(), i1.ɵeld(30, 0, null, null, 2, "button", [["class", "pac controls"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i22.View_MatButton_0, i22.RenderType_MatButton)), i1.ɵdid(31, 180224, null, 0, i23.MatButton, [i1.ElementRef, i24.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(32, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.lng; var currVal_2 = _co.lat; var currVal_3 = 15; var currVal_4 = false; _ck(_v, 11, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.lat; var currVal_6 = _co.lng; var currVal_7 = true; _ck(_v, 15, 0, currVal_5, currVal_6, currVal_7); var currVal_16 = _co.searchControl; _ck(_v, 21, 0, currVal_16); var currVal_17 = _co.detectingPosition; _ck(_v, 25, 0, currVal_17); var currVal_20 = !_co.locationSelected; _ck(_v, 28, 0, currVal_20); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_8 = _co.brandInformation.translations.conversation.shareLocation.searchLocation; var currVal_9 = i1.ɵnov(_v, 23).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 23).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 23).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 23).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 23).ngClassValid; var currVal_14 = i1.ɵnov(_v, 23).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 23).ngClassPending; _ck(_v, 18, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_18 = (i1.ɵnov(_v, 28).disabled || null); var currVal_19 = (i1.ɵnov(_v, 28)._animationMode === "NoopAnimations"); _ck(_v, 27, 0, currVal_18, currVal_19); var currVal_21 = _co.brandInformation.translations.conversation.shareLocation.shareLocationButton; _ck(_v, 29, 0, currVal_21); var currVal_22 = (i1.ɵnov(_v, 31).disabled || null); var currVal_23 = (i1.ɵnov(_v, 31)._animationMode === "NoopAnimations"); _ck(_v, 30, 0, currVal_22, currVal_23); var currVal_24 = _co.brandInformation.translations.conversation.shareLocation.abortButton; _ck(_v, 32, 0, currVal_24); }); }
export function View_MapLocationPickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map-location-picker", [], null, null, null, View_MapLocationPickerComponent_0, RenderType_MapLocationPickerComponent)), i1.ɵdid(1, 114688, null, 0, i25.MapLocationPickerComponent, [i17.MapsAPILoader, i1.NgZone, i26.OverlayRef, i5.PlatformLocation], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapLocationPickerComponentNgFactory = i1.ɵccf("app-map-location-picker", i25.MapLocationPickerComponent, View_MapLocationPickerComponent_Host_0, {}, {}, []);
export { MapLocationPickerComponentNgFactory as MapLocationPickerComponentNgFactory };
