/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-image.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./media-image.component";
import * as i4 from "../../services/images/safe-media-url-provider-factory.service";
import * as i5 from "../../../shared/image-overlay.service";
var styles_MediaImageComponent = [i0.styles];
var RenderType_MediaImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaImageComponent, data: {} });
export { RenderType_MediaImageComponent as RenderType_MediaImageComponent };
function View_MediaImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img", [], [[8, "src", 4], [8, "alt", 0]], [[null, "click"], [null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openImageOverlay() !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (_co.onImageLoaded() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.getUrl())); var currVal_1 = _co.getDescription(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MediaImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["broken_image"]))], null, null); }
export function View_MediaImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaImageComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaImageComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasImage(); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.hasImage(); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_MediaImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-conversation-message-image", [], null, null, null, View_MediaImageComponent_0, RenderType_MediaImageComponent)), i1.ɵdid(1, 114688, null, 0, i3.MediaImageComponent, [i4.SafeMediaUrlProviderFactory, i5.ImageOverlayService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MediaImageComponentNgFactory = i1.ɵccf("app-conversation-message-image", i3.MediaImageComponent, View_MediaImageComponent_Host_0, { message: "message" }, { imageLoadedEvent: "imageLoadedEvent" }, []);
export { MediaImageComponentNgFactory as MediaImageComponentNgFactory };
