import { Component, Input, NgZone } from '@angular/core';
import { ConversationClientService } from '../../conversation/services/stomp/conversation-client.service';
import { stompConfig } from '../../conversation/services/stomp/websocket-config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent {

  @Input() backLink = null;
  @Input() title = '';
  @Input() menu;
  backArrowVisible = true;

  constructor(private conversationClientService: ConversationClientService, private ngZone: NgZone, private router: Router) {
  }

  click() {
    this.ngZone.run (() => this.router.navigate(this.backLink));
  }

  public isConnected(): boolean {
    return this.conversationClientService.isConnected();
  }

  public isLiveBackend(): boolean {
    return 'wss://web-conversation.conversationalmessaging.com/web-conversation-public/conversation' === stompConfig.brokerURL;
  }

  public setBackArrowVisibility(visible: boolean) {
    this.backArrowVisible = visible;
  }
}
