import { deserialize } from 'class-transformer';

export class CustomerBrandInformation {

  public headline: string;
  public description: string;
  public phone: string;
  public website: string;
  public email: string;
  public urlPrivacyPolicy: string;
  public urlTermsOfService: string;
  public colorCode: string;
  public heroImageUrl: string;
  public iconUrl: string;
  public imageStorageSizeThresholdInKb: number;
  public locale: string;

  public translations: any;

  constructor() {
  }

  public static fromJson(json: string): CustomerBrandInformation {
    // TODO Remove json.replace if the backend is released
    return deserialize(CustomerBrandInformation, json.replace('"imageStorageSizeTresholdInKb"', '"imageStorageSizeThresholdInKb"'));
  }

  public toString(): string {
    return `CustomerBrandInformation (headline: ${this.headline}, `
      + `description: ${this.description}, phone: ${this.phone}, website: ${this.website}, `
      + `email: ${this.email}, urlPrivacyPolicy: ${this.urlPrivacyPolicy}, `
      + `urlTermsOfService: ${this.urlTermsOfService}, colorCode: ${this.colorCode}, `
      + `heroImageUrl: ${this.heroImageUrl}, iconUrl: ${this.iconUrl}, `
      + `imageStorageSizeThresholdInKb: ${this.imageStorageSizeThresholdInKb}, `
      + `locale: ${this.locale})`;
  }
}
