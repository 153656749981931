/// <reference types="googlemaps" />
import { Component, ElementRef, NgZone, Input, OnInit, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { OverlayRef } from '@angular/cdk/overlay';
import { LatLng } from '../../conversation/model/suggestion';
import { PlatformLocation } from '@angular/common';
import { CustomerBrandInformation } from '../../conversation/model/customer-brand-information';

@Component({
  selector: 'app-map-location-picker',
  templateUrl: './map-location-picker.component.html',
  styleUrls: ['./map-location-picker.component.css']
})
export class MapLocationPickerComponent implements OnInit {

  // Default location is Hamburg
  lat = 53.551086;
  lng = 9.993682;
  shareLocationAnchor = '#sl';
  address: string = null || null;
  detectingPosition = false;
  locationSelected = false;

  public searchControl: FormControl;

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;

  public callback: (location: LatLng, address: string) => void;
  public brandInformation: CustomerBrandInformation;

  map: any;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private overlayRef: OverlayRef,
    private location: PlatformLocation) {

    this.closeOnBackPressed();
  }

  private closeOnBackPressed() {
    this.cleanupState();

    this.location.pushState(null, 'Share Location', window.location.href + this.shareLocationAnchor);
    // Perform close() in next tick, otherwise the conversation is scrolled all the way
    // to the top.
    this.location.onPopState(() => setTimeout(() => this.close(), 0));
  }

  private cleanupState() {
    if (window.location.href.endsWith(this.shareLocationAnchor)) {
      // Safeguard if URL is called directly with the share anchor
      this.location.replaceState(null, '', window.location.href.slice(0, -this.shareLocationAnchor.length));
    }
  }

  ngOnInit() {
    this.searchControl = new FormControl();
    // set current position
    this.getPositionFromBrowser();

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        type: 'address'
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry == null) {
            return;
          }

          // set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.address = place.formatted_address;
          this.locationSelected = true;
        });
      });
    });
  }

  close() {
    this.cleanupState();
    this.overlayRef.dispose();
    this.callback(null, null);
  }

  applyLocation() {
    if (!this.locationSelected) {
      console.log('Cannot send location data. Latitude/Longitude is undefined.');
      return;
    }
    this.cleanupState();
    this.overlayRef.dispose();

    if (this.address == null) {
      this.getAddressOfPosition().then( data => {
        this.callback(LatLng.latLng(this.lat, this.lng), data);
      });
    } else {
      this.callback(LatLng.latLng(this.lat, this.lng), this.address);
    }
  }

  placeMarker(event) {
    // Update marker postion
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.address = null;
    this.locationSelected = true;
    this.detectingPosition = false;
  }

  mapReady(event) {
    this.map = event;

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('bar'));
    this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('detectingPosition'));
    this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(document.getElementById('bottom-bar'));
  }

  private getPositionFromBrowser() {

    // Requesting Browser Location if supported
    if (!!navigator.geolocation) {
      this.detectingPosition = true;
      navigator.geolocation.getCurrentPosition((postition) => {
        this.detectingPosition = false;
        if (!this.locationSelected) {
          this.lat = postition.coords.latitude;
          this.lng = postition.coords.longitude;
          this.locationSelected = true;
        }
      }, (error) => {
        this.detectingPosition = false;
        console.log('Error during Browser location request:' + error);
      }, { timeout: 8000 });
    }

  }

  private getAddressOfPosition(): Promise<string> {
   return new Promise<string>(resolve => {
      const geocoder = new google.maps.Geocoder();
      const location = {lat: this.lat, lng: this.lng};
      geocoder.geocode({'location': location}, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            resolve(results[1].formatted_address);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
    });
  }
}
