import { Injectable } from '@angular/core';
import { ConversationClientService } from './stomp/conversation-client.service';
import { MessageRepositoryService } from './store/message-repository.service';
import { AbstractMessage, MediaFile } from '../model/abstract-message';
import { AgentMessage } from '../model/agent-message';
import { ClientMessage, ClientMessageAck } from '../model/client-message';
import { Party } from '../model/party';
import { LatLng, SuggestedReply } from '../model/suggestion';
import { environment } from '../../../environments/environment';
import { ImageService } from './images/image.service';
import { BrandInformationService } from './brand-information.service';
import { FileRepositoryService } from '../../shared/file-repository.service';

@Injectable()
export class MessageService {

  conversationMessages: Array<AbstractMessage> = [];

  constructor(private conversationClient: ConversationClientService,
              private messageRepository: MessageRepositoryService,
              private fileRepository: FileRepositoryService,
              private imageService: ImageService,
              private brandInfoService: BrandInformationService) {
  }

  subscribe(conversationHash: string) {
    this.conversationClient.subscribeForAgentMessages(conversationHash, this.handleAgentMessage);
    this.conversationClient.subscribeForClientMessageAcks(conversationHash, this.handleClientMessageAck);
  }

  public async loadStoredMessages(conversationHash: string): Promise<Array<AbstractMessage>> {
    return new Promise<Array<AbstractMessage>>(resolve => {
        this.messageRepository.loadConversation(conversationHash)
        .then(messages => this.conversationMessages = messages)
        .then(messages => resolve(messages))
        .catch(reason => {
          console.error(`MessageService: Could not load messages from repository: ${reason}`);
        })
        .finally(() => {
          this.subscribe(conversationHash);
        });
    });
  }

  /** Consume a message from the stompService */
  handleAgentMessage = (conversationHash: string, message: AgentMessage) => {
    console.info(`MessageService: received ${message}`);
    this.addMessage(conversationHash, message).then(() => {
      this.updateMessageImage(conversationHash, message);
    });
  };

  updateMessageImage(conversationHash: string, message: AgentMessage) {
    this.brandInfoService.getBrandInformation(conversationHash).then(async (brandInfo) => {
      const downloadThreshold = brandInfo.imageStorageSizeThresholdInKb * 1024;
      let update = false;

      if (message.hasImage()) {
        update = await this.downloadMessageMedia(conversationHash, message, message.mediaFile, downloadThreshold) || update;
      } else if (message.hasStandaloneCard() && message.standaloneCard.hasImage()) {
        update = await this.downloadMessageMedia(conversationHash, message, message.standaloneCard.content.mediaFile, downloadThreshold) || update;
      } else if (message.hasCarouselCard()) {
        const promises = message.carouselCard.cards.map( (card) => {
          if (card.mediaFile) {
            return this.downloadMessageMedia(conversationHash, message, card.mediaFile, downloadThreshold);
          }
        });
        const updates = await Promise.all(promises);
        update = updates.some(u => u);
      }

      if (update) {
        this.messageRepository.updateMessage(conversationHash, message);
      }
    });
  }

  private async downloadMessageMedia(conversationHash: string, message: AgentMessage, mediaFile: MediaFile, threshold: number) {
    let update = false;

    if (mediaFile.hasThumbnail() && mediaFile.thumbnailContentLength <= threshold) {
      const dataBlob = await this.imageService.downloadFileAsBlob(mediaFile.thumbnailUrl);
      const dataArray = await this.imageService.blobToArray(dataBlob);
      const hash = await this.fileRepository.storeFile(dataArray);
      if (hash) {
        mediaFile.thumbnailFilesId = hash;
        update = true;
      }
    }

    if (mediaFile.fileUrl && mediaFile.contentLength <= threshold) {
      const dataBlob = await this.imageService.downloadFileAsBlob(mediaFile.fileUrl);
      const dataArray = await this.imageService.blobToArray(dataBlob);
      const hash = await this.fileRepository.storeFile(dataArray);
      if (hash) {
        mediaFile.filesId = hash;
        update = true;
      }
    }

    return update;
  }

  private handleClientMessageAck = (conversationHash: string, message: ClientMessageAck) => {
    console.info(`MessageService: received ack ${message}`);
    const index = this.findMessageIndexById(message.id);
    if (index === -1) {
      return;
    }

    const clientMessage = this.conversationMessages[index];
    clientMessage.timestamp = message.timestamp;
    this.conversationMessages.splice(index, 1);
    this.insertMessageSorted(clientMessage);
    this.messageRepository.updateMessage(conversationHash, clientMessage);
  };

  public sendTextClientMessage(conversationHash: string, message: ClientMessage) {
    this.addMessage(conversationHash, message);
    this.conversationClient.sendClientMessage(conversationHash, message);
  }

  public sendMediaClientMessage(conversationHash: string, message: ClientMessage, fileData: Uint8Array, resizedImageFile: Uint8Array, progressHandler?: (percent: number) => void) {
    if (!message.mediaFile) {
      return;
    }
    return this.conversationClient.uploadImageMessage(conversationHash, message, fileData, progressHandler).then( (response) => {
      // Add File to DB
      this.fileRepository.storeFile(response).then( (key) => {
        if (key && response.length > 0) {
          message.mediaFile.filesId = key;
          message.mediaFile.mimeType = 'image/jpeg';
          message.mediaFile.contentLength = response.length;
        }
        // Add Msg to DB
        this.addMessage(conversationHash, message);
      });
    });
  }

  public sendSuggestionResponseClientMessage(conversationHash: string, messageId: string, timestamp: number, reply: SuggestedReply) {
    this.addMessage(conversationHash, ClientMessage.clientTextMessage(messageId, reply.text, timestamp));
    this.sendSuggestionClickedEventMessage(conversationHash, messageId, timestamp, reply);
  }

  public sendSuggestionClickedEventMessage(conversationHash: string, messageId: string, timestamp: number, reply: SuggestedReply) {
    this.conversationClient.sendClientMessage(conversationHash, ClientMessage.clientSuggestionResponseMessage(messageId, reply, timestamp));
  }

  public sendShareLocationClientMessage(conversationHash: string, messageId: string, timestamp: number, location: LatLng, address: string) {
    this.addMessage(conversationHash, ClientMessage.clientTextMessage(messageId, (address != null ? address + '<br>' : '')
      + environment.googlemaps.url + location.latitude + ',' + location.longitude, timestamp));
    this.conversationClient.sendClientMessage(conversationHash, ClientMessage.clientShareLocationMessage(messageId, location, timestamp));
  }

  public sendPushSubscription(conversationHash: string, subscription: string) {
    this.conversationClient.sendPushSubscription(conversationHash, subscription);
  }

  public verifyPushSubscription(conversationHash: string, subscription: string) {
    this.conversationClient.verifyPushSubscription(conversationHash, subscription);
  }

  public updateStaticMapImageMessage(conversationHash: string, messageId: string, data: Blob) {
    const message: AbstractMessage = this.findMessageById(messageId);
    if (message == null) {
      return;
    }

    this.imageService.blobToArray(data).then( (dataArray) => {
      this.fileRepository.storeFile(dataArray).then( hash => {
        message.mediaFile = new MediaFile();
        message.mediaFile.filesId = hash;
        message.mediaFile.mimeType = data.type;
        this.messageRepository.updateMessage(conversationHash, message);
      });
    }).catch(err => console.error('Failed to store blob in file repository: ' + err.error));
  }

  private async addMessage(conversationHash: string, message: AbstractMessage) {
    const inserted = this.insertMessageSorted(message);
    if (inserted) {
      this.clearSuggestionsFromPreviousAgentMessages(conversationHash);
      return this.messageRepository.storeMessage(conversationHash, message);
    }
    return '';
  }

  public updateOpenUrlMessage(messageId: string, conversationHash: string) {
    const index = this.findMessageIndexById(messageId);
    if (index === -1) {
      return;
    }
    const agentMessage = this.conversationMessages[index] as AgentMessage;
    this.messageRepository.updateMessage(conversationHash, agentMessage);
 }

  private insertMessageSorted(currentMessage: AbstractMessage): boolean {
    let i = this.conversationMessages.length;
    let insert = true;
    // iterate until a message with earlier or same timestamp is found (or not found at all)
    while (i > 0 && this.conversationMessages[i - 1].timestamp > currentMessage.timestamp) {
      i--;
    }
    let j = i - 1;
    // if the messages have the same timestamp, check that non of the already included hat the same ID
    while (j >= 0 && this.conversationMessages[j].timestamp === currentMessage.timestamp && insert) {
      insert = (this.conversationMessages[j].id !== currentMessage.id);
      j--;
    }
    if (insert) {
      this.conversationMessages.splice(i, 0, currentMessage);
    }
    return insert;
  }

  private clearSuggestionsFromPreviousAgentMessages(conversationHash: string) {
    // we do not check the very last message in the array, so exclude the last element
    // while slicing
    this.conversationMessages.slice(0, this.conversationMessages.length - 1)
      .forEach(msg => {
        this.removeSuggestionsFromAgentMessage(conversationHash, msg);
      });
  }

  private removeSuggestionsFromAgentMessage(conversationHash: string, message: AbstractMessage) {
    if (message.isMessageType(Party.AGENT)) {
      const agentMessage = message as AgentMessage;
      if (agentMessage.hasSuggestions()) {
        agentMessage.removeSuggestions();
        this.messageRepository.updateMessage(conversationHash, agentMessage);
      }
    }
  }

  private findMessageById(messageId: string): AbstractMessage {
    const index = this.findMessageIndexById(messageId);
    if (index === -1) {
      return null;
    }
    return this.conversationMessages[index];
  }

  private findMessageIndexById(messageId: string): number {
    const messages: Array<AbstractMessage> = this.conversationMessages;
    let i = this.conversationMessages.length - 1;
    while (i >= 0) {
      const message: AbstractMessage = messages[i];
      if (message.id === messageId) {
        return i;
      }
      i--;
    }
    return -1;
  }

  public isConnected(): boolean {
    return this.conversationClient.isConnected();
  }
  public unsubscribe() {
    this.conversationClient.unsubscribeFromAgentMessage();
    this.conversationClient.unsubscribeFromClientMessageAck();
  }
}
