<div class="inner" id="inner">
<app-top-bar [title]="brandInformation?.headline" [backLink]="['/conversation']"></app-top-bar>
<div id="main" (touchstart)="touchstart($event)" (touchend)="touchend($event)" #main [ngClass]="messageDivStyle"  name="main" (scroll)="onElementScroll($event)">
  <!--show messages-->
  <div id="top"></div>
  <app-brand-header [hasMessages]="(messages | async)?.length > 0" [brandInformation]="brandInformation"></app-brand-header>
  <div id="scrollAnimate" (click)="scrollComplete()"></div>
  <div id="messageList" name="messageList" class="message-list" *ngIf="(messages | async)?.length > 0; else loadingMessages">
    <div #messageList *ngFor="let message of messages | async; trackBy:trackByMsgId">
      <app-conversation-message [message]="message" [brandInformation]="brandInformation" (doScrollingEvent)="scroll()"></app-conversation-message>
    </div>
  </div>

  <!--no message-->
  <ng-template #loadingMessages>
    <h3 class="status-message">{{brandInformation ? brandInformation.translations.conversation.messages.waitingForMessages : 'Waiting for messages ...'}}</h3>
  </ng-template>

  <div id="bottom"></div>
  <button mat-mini-fab *ngIf="showScrollButton" class="floating-action-button" (click)="scroll()">
    <mat-icon class="mat-18" [attr.aria-label]="brandInformation ? brandInformation.translations.conversation.messages.scrollToBottomButtonTooltip : 'Button to scroll to bottom'">expand_more</mat-icon>
  </button>

</div>

<div id="submit">

<app-conversation-message-submit-form  [showCursor]="showInputFocus" (onFocusEvent)="inputFocusEvent($event)" [conversationHash]="conversationHash" [brandInformation]="brandInformation"
    *ngIf="(messages | async)?.length > 0 && allowFreeTextInput"></app-conversation-message-submit-form>

</div>
</div>





