import { ComponentRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ImageOverlayComponent } from './image-overlay/image-overlay.component';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { FILE_PREVIEW_DIALOG_DATA } from './image-overlay.token';
import { SafeUrl } from '@angular/platform-browser';

interface ImageOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  url?: SafeUrl;
}

const DEFAULT_CONFIG: ImageOverlayConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'image-overlay-dialog',
  url: undefined,
};

@Injectable()
export class ImageOverlayService {

  constructor(private injector: Injector,
              private overlay: Overlay) {
  }

  open(url: SafeUrl) {
    const config: ImageOverlayConfig = DEFAULT_CONFIG;
    config.url = url;
    this.attachDialogContainer(config, this.createOverlay(config));
  }

  private createOverlay(config: ImageOverlayConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: ImageOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }

  private attachDialogContainer(config: ImageOverlayConfig, overlayRef: OverlayRef) {
    const injector = this.createInjector(config, overlayRef);

    const containerPortal = new ComponentPortal(ImageOverlayComponent, null, injector);
    const containerRef: ComponentRef<ImageOverlayComponent> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private createInjector(config: ImageOverlayConfig, overlayRef: OverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(OverlayRef, overlayRef);
    injectionTokens.set(FILE_PREVIEW_DIALOG_DATA, config.url);

    return new PortalInjector(this.injector, injectionTokens);
  }

}
