import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { load as loadWebFont } from 'webfontloader';

if (environment.production) {
  enableProdMode();
}

if (location.hash.length > 0 && location.hash.substring(0, 2) === '#!') {
  // https://angular.io/guide/router#browser-url-styles
  // https://keita.blog/2015/11/24/hosting-a-single-page-app-on-s3-with-proper-urls/
  // #!/signup/confirm -> /signup/confirm
  location.replace(location.hash.substring(2));
}

loadWebFont({
  google: {
    families: ['Material Icons', 'Roboto']
  }
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
