import { Component, Inject, OnInit } from '@angular/core';
import { FILE_PREVIEW_DIALOG_DATA } from '../image-overlay.token';
import { OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'app-image-overlay',
  templateUrl: './image-overlay.component.html',
  styleUrls: ['./image-overlay.component.css']
})
export class ImageOverlayComponent implements OnInit {

  constructor(private overlayRef: OverlayRef, @Inject(FILE_PREVIEW_DIALOG_DATA) public url: string) { }

  close() {
    this.overlayRef.dispose();
  }

  ngOnInit() {
  }

}
