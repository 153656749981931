/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-linky";
import * as i3 from "../../../shared/pipes/html-escape.pipe";
import * as i4 from "@angular/common";
import * as i5 from "./text.component";
var styles_TextComponent = [i0.styles];
var RenderType_TextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextComponent, data: {} });
export { RenderType_TextComponent as RenderType_TextComponent };
export function View_TextComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LinkyPipe, []), i1.ɵpid(0, i3.HtmlEscapePipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "body"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "client": 0, "agent": 1, "arabic-text": 2 }), i1.ɵppd(6, 1), i1.ɵpod(7, { className: 0, stripPrefix: 1 }), i1.ɵppd(8, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "body"; var currVal_2 = _ck(_v, 5, 0, (_co.message.party == 0), (_co.message.party == 1), _co.isPercentageArabic()); _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 2, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 1), _co.message.text)), _ck(_v, 7, 0, ((_co.message.party == 0) ? "link-underline-black" : "link-underline-white"), false))); _ck(_v, 2, 0, currVal_0); }); }
export function View_TextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-conversation-message-text", [], null, null, null, View_TextComponent_0, RenderType_TextComponent)), i1.ɵdid(1, 49152, null, 0, i5.TextComponent, [], null, null)], null, null); }
var TextComponentNgFactory = i1.ɵccf("app-conversation-message-text", i5.TextComponent, View_TextComponent_Host_0, { message: "message" }, {}, []);
export { TextComponentNgFactory as TextComponentNgFactory };
