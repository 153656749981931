import {AfterContentChecked, ChangeDetectorRef, Component, HostListener, Inject, Input, NgZone, OnDestroy, OnInit, ViewChild, ViewChildren, QueryList, ElementRef} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MessageService} from './services/message.service';
import {AbstractMessage} from './model/abstract-message';
import {AgentMessage} from './model/agent-message';
import {CustomerBrandInformation} from './model/customer-brand-information';
import {BrandInformationService} from './services/brand-information.service';
import {environment} from '../../environments/environment';
import {DOCUMENT} from '@angular/common';
import {UpdateService} from '../shared/update.service';
import {MapLocationPickerService} from '../shared/map-location-picker.service';
import {TopBarComponent} from '../shared/top-bar/top-bar.component';
import {SwPush} from '@angular/service-worker';
import { ViewportScroller } from '@angular/common';
import * as $ from 'jquery';
import { WindowRefService } from './services/window-ref.service';


@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.css']
})
export class ConversationComponent implements OnInit, OnDestroy, AfterContentChecked {

  conversationHash: string;
  @Input()
  brandInformation: CustomerBrandInformation = null;
  messages: Promise<Array<AbstractMessage>> | null = null;
  showScrollButton = false;
  messageDivStyle = 'nomessages';
  messageLength = 0;
  showInputFocus = true;

  allowFreeTextInput = true;

  inputIsFocused = false;

  windowScroll = false;

  someSubscription: any;

  delta;

  @ViewChild('main', { static: true }) messageDiv: ElementRef;
  @ViewChild('submit', { static: true }) submitDiv: ElementRef;

  @ViewChild(TopBarComponent, { static: true }) topBar: TopBarComponent;

  @ViewChildren('messageList') messageList: QueryList<any>;

  readonly _window: Window;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private brandInformationService: BrandInformationService,
              private messageService: MessageService,
              private scroller: ViewportScroller,
              private updateService: UpdateService,
              private mapLocationService: MapLocationPickerService,
              private swPush: SwPush,
              @Inject(DOCUMENT) private document: any,
              private windowRefService: WindowRefService) {
    this._window = windowRefService.getWindowRef();

    this.mapLocationService.onOverlayOpened.subscribe(() => {
      this.topBar.setBackArrowVisibility(false);
    });
    this.mapLocationService.onOverlayClosed.subscribe(() => {
      this.topBar.setBackArrowVisibility(true);
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.someSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  @HostListener('focus', ['$event'])
  setInputFocus(event): void {
  }

  ngAfterContentChecked(): void {
  }

  ngOnInit() {
    if (this.conversationHash == null) {
      this.conversationHash = this.route.snapshot.parent.paramMap.get('hash');
    }
    if (this.conversationHash) {
      this.brandInformationService
        .getBrandInformation(this.conversationHash)
        .then((brandinfo) => {
          this.brandInformation = brandinfo;
          this.updateConversationList(this.conversationHash);
          this.subscribeToConversation();
          try {
            this.setupPushNotifications(this.conversationHash, this.messageService);
          } catch (error) {
            console.log('Fail to subscribe to notification', error);
          }
        })
        .catch(error => {
          console.error('Error retrieving brand information: {}', error);
          this.router.navigate(['/conversation'], {queryParams: {status: 'notfound'}});
          return null;
        });
    } else {
      console.info('No conversation hash');
    }

  }

  setupPushNotifications(convHash: string, messageService: MessageService) {
    try {
    if (this.swPush.isEnabled && ('PushManager' in window || 'pushManager' in ServiceWorkerRegistration.prototype) && this.swPush.subscription) {
      this.swPush.subscription.subscribe((subscription: PushSubscription) => {
        try {
        if (subscription) {
          const subStr = JSON.stringify(subscription);
          console.info('Verify push subscription:', subStr);
          messageService.verifyPushSubscription(convHash, subStr);
        } else {
          this.swPush.requestSubscription({
            serverPublicKey: environment.pushPublicKey
          }).then((sub: PushSubscription) => {
            const subStr = JSON.stringify(sub.toJSON());
            console.info('Registered for push notifications. subscription:', subStr);
            messageService.sendPushSubscription(convHash, subStr);
          }).catch(function (error) {
            console.warn('Push notification registration error', error);
          });
        }
      } catch (error) {
        console.warn('Push notification registration error', error);
      }});
    } else {
      console.warn('Push Notifications are not supported');
    }
    } catch (e) {
      console.warn('Error handler', e);
    }
  }

  updateConversationList(conversationHash: string) {
    let conversations: Array<string> = JSON.parse(localStorage.getItem('conversartion_list'));
    if (conversations == null) {
      conversations = [];
    }
    if (!conversations.includes(conversationHash)) {
      conversations.push(conversationHash);
      localStorage.setItem('conversartion_list', JSON.stringify(conversations));
    }
  }

  ngOnDestroy() {
    if (this.someSubscription) {
      this.someSubscription.unsubscribe();
    }
    this.messageService.unsubscribe();
  }

  trackByMsgId(index: number, msg: AbstractMessage): string {
    return msg.id;
  }

  private checkShouldShowAllowFreeText(messagesAsArray: Array<AbstractMessage>) {
     if (messagesAsArray && messagesAsArray.length > 0) {
      if (this.messageDivStyle === 'nomessages') {
         this.messageDivStyle = 'hasmessages';
       }
       const agentMessages = messagesAsArray.filter(m => m instanceof AgentMessage);
       let messageLength = 0;
       if (agentMessages && agentMessages.length > 0) {
         messageLength = agentMessages.length;
         const last: AbstractMessage = agentMessages[agentMessages.length - 1];
         this.allowFreeTextInput = !last.hideFreeText;
       }
       if (messageLength !== this.messageLength) {
         this.messageLength = messageLength;
         this.scroll();
       }
     }
  }

  private subscribeToConversation() {
    this.messages = this.messageService.loadStoredMessages(this.conversationHash);
    this.messages.then((messagesAsArray) => {
      this.subscribeToListChanges();
    });
  }

  private subscribeToListChanges() {
    this.messageList.changes.subscribe(() => {
      this.messages.then((messagesAsArray) => {
      this.checkShouldShowAllowFreeText(messagesAsArray);
      });
      this.updateService.checkForUpdate();
    });
  }

  @HostListener('window:keydown', ['$event'])
  @HostListener('window:touchstart', ['$event'])
  @HostListener('window:touchend', ['$event'])
  @HostListener('window:mousedown', ['$event'])
  @HostListener('window:click', ['$event'])
  @HostListener('window:mouseup', ['$event'])
  onWindowEvent(event): any {
     return null;
  }

  @HostListener('window:suggestion.clicked', ['$event'])
  onSuggestionClickEvent(event) {
  }


  public scroll(duration: number = 400) {
    document.getElementById('bottom').scrollIntoView({
    behavior: 'smooth',
    block: 'start'
    });
  }

  onElementScroll($event) {
    const pos = ( this.messageDiv.nativeElement.scrollTop || document.body.scrollTop) + this.messageDiv.nativeElement.offsetHeight;
    const max =  this.messageDiv.nativeElement.scrollHeight - this.messageDiv.nativeElement.offsetHeight;
    this.showScrollButton = pos < max;
    if (this.inputIsFocused) {
      document.getElementById('textInput').scrollIntoView();
    }
  }

  cancelEvent(event) {
    if (event && event.cancelable) {
      event.preventDefault();
    }
    if (event) {
      event.stopPropagation();
    }
  }

  touchstart($event) {
    const pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    const max = document.body.scrollHeight;
    if (this.inputIsFocused && this.windowScroll) {
      this.cancelEvent($event);
    }
    if (this.inputIsFocused && !this.delta) {
      this.delta = pos - max;
    }
  }

  scrollComplete() {
    this.delta = 0;
    this.windowScroll = false;
    this.showInputFocus = true;
  }

  touchend($event) {
    if (this.inputIsFocused && this.windowScroll) {
      this.cancelEvent($event);
    }
    if (this.inputIsFocused && this.delta) {
      const pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
      const max = document.body.scrollHeight;
      const delta = pos - max;
      if (this.delta === delta) {
        this.scrollComplete();
      } else if (this.delta > 0) {
        this.windowScroll = true;
        $('html, body').animate({
        scrollTop: this.delta,
        }, 10, function() { $('#scrollAnimate').trigger('click'); });
      }
    }
  }

  inputFocusEvent($event) {
    this.inputIsFocused = $event;
    if (this.inputIsFocused) {
      setTimeout(() => {
        this.scroll();
      }, 500);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    if (this.inputIsFocused && this.delta && !this.windowScroll) {
      this.showInputFocus = false;
    }
  }

  @HostListener('body:scroll', ['$event'])
  onBodyScroll($event) {
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('body:resize', ['$event'])
  onWindowResize($event) {
  }

}


