import { Injectable } from '@angular/core';

@Injectable()
export class WindowRefService {

  constructor() { }

  public getWindowRef(): any {
    return window;
  }
}
