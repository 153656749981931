import * as tslib_1 from "tslib";
import { ConversationClientService } from './stomp/conversation-client.service';
import { MessageRepositoryService } from './store/message-repository.service';
import { MediaFile } from '../model/abstract-message';
import { ClientMessage } from '../model/client-message';
import { Party } from '../model/party';
import { environment } from '../../../environments/environment';
import { ImageService } from './images/image.service';
import { BrandInformationService } from './brand-information.service';
import { FileRepositoryService } from '../../shared/file-repository.service';
export class MessageService {
    constructor(conversationClient, messageRepository, fileRepository, imageService, brandInfoService) {
        this.conversationClient = conversationClient;
        this.messageRepository = messageRepository;
        this.fileRepository = fileRepository;
        this.imageService = imageService;
        this.brandInfoService = brandInfoService;
        this.conversationMessages = [];
        /** Consume a message from the stompService */
        this.handleAgentMessage = (conversationHash, message) => {
            console.info(`MessageService: received ${message}`);
            this.addMessage(conversationHash, message).then(() => {
                this.updateMessageImage(conversationHash, message);
            });
        };
        this.handleClientMessageAck = (conversationHash, message) => {
            console.info(`MessageService: received ack ${message}`);
            const index = this.findMessageIndexById(message.id);
            if (index === -1) {
                return;
            }
            const clientMessage = this.conversationMessages[index];
            clientMessage.timestamp = message.timestamp;
            this.conversationMessages.splice(index, 1);
            this.insertMessageSorted(clientMessage);
            this.messageRepository.updateMessage(conversationHash, clientMessage);
        };
    }
    subscribe(conversationHash) {
        this.conversationClient.subscribeForAgentMessages(conversationHash, this.handleAgentMessage);
        this.conversationClient.subscribeForClientMessageAcks(conversationHash, this.handleClientMessageAck);
    }
    loadStoredMessages(conversationHash) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.messageRepository.loadConversation(conversationHash)
                    .then(messages => this.conversationMessages = messages)
                    .then(messages => resolve(messages))
                    .catch(reason => {
                    console.error(`MessageService: Could not load messages from repository: ${reason}`);
                })
                    .finally(() => {
                    this.subscribe(conversationHash);
                });
            });
        });
    }
    updateMessageImage(conversationHash, message) {
        this.brandInfoService.getBrandInformation(conversationHash).then((brandInfo) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const downloadThreshold = brandInfo.imageStorageSizeThresholdInKb * 1024;
            let update = false;
            if (message.hasImage()) {
                update = (yield this.downloadMessageMedia(conversationHash, message, message.mediaFile, downloadThreshold)) || update;
            }
            else if (message.hasStandaloneCard() && message.standaloneCard.hasImage()) {
                update = (yield this.downloadMessageMedia(conversationHash, message, message.standaloneCard.content.mediaFile, downloadThreshold)) || update;
            }
            else if (message.hasCarouselCard()) {
                const promises = message.carouselCard.cards.map((card) => {
                    if (card.mediaFile) {
                        return this.downloadMessageMedia(conversationHash, message, card.mediaFile, downloadThreshold);
                    }
                });
                const updates = yield Promise.all(promises);
                update = updates.some(u => u);
            }
            if (update) {
                this.messageRepository.updateMessage(conversationHash, message);
            }
        }));
    }
    downloadMessageMedia(conversationHash, message, mediaFile, threshold) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let update = false;
            if (mediaFile.hasThumbnail() && mediaFile.thumbnailContentLength <= threshold) {
                const dataBlob = yield this.imageService.downloadFileAsBlob(mediaFile.thumbnailUrl);
                const dataArray = yield this.imageService.blobToArray(dataBlob);
                const hash = yield this.fileRepository.storeFile(dataArray);
                if (hash) {
                    mediaFile.thumbnailFilesId = hash;
                    update = true;
                }
            }
            if (mediaFile.fileUrl && mediaFile.contentLength <= threshold) {
                const dataBlob = yield this.imageService.downloadFileAsBlob(mediaFile.fileUrl);
                const dataArray = yield this.imageService.blobToArray(dataBlob);
                const hash = yield this.fileRepository.storeFile(dataArray);
                if (hash) {
                    mediaFile.filesId = hash;
                    update = true;
                }
            }
            return update;
        });
    }
    sendTextClientMessage(conversationHash, message) {
        this.addMessage(conversationHash, message);
        this.conversationClient.sendClientMessage(conversationHash, message);
    }
    sendMediaClientMessage(conversationHash, message, fileData, resizedImageFile, progressHandler) {
        if (!message.mediaFile) {
            return;
        }
        return this.conversationClient.uploadImageMessage(conversationHash, message, fileData, progressHandler).then((response) => {
            // Add File to DB
            this.fileRepository.storeFile(response).then((key) => {
                if (key && response.length > 0) {
                    message.mediaFile.filesId = key;
                    message.mediaFile.mimeType = 'image/jpeg';
                    message.mediaFile.contentLength = response.length;
                }
                // Add Msg to DB
                this.addMessage(conversationHash, message);
            });
        });
    }
    sendSuggestionResponseClientMessage(conversationHash, messageId, timestamp, reply) {
        this.addMessage(conversationHash, ClientMessage.clientTextMessage(messageId, reply.text, timestamp));
        this.sendSuggestionClickedEventMessage(conversationHash, messageId, timestamp, reply);
    }
    sendSuggestionClickedEventMessage(conversationHash, messageId, timestamp, reply) {
        this.conversationClient.sendClientMessage(conversationHash, ClientMessage.clientSuggestionResponseMessage(messageId, reply, timestamp));
    }
    sendShareLocationClientMessage(conversationHash, messageId, timestamp, location, address) {
        this.addMessage(conversationHash, ClientMessage.clientTextMessage(messageId, (address != null ? address + '<br>' : '')
            + environment.googlemaps.url + location.latitude + ',' + location.longitude, timestamp));
        this.conversationClient.sendClientMessage(conversationHash, ClientMessage.clientShareLocationMessage(messageId, location, timestamp));
    }
    sendPushSubscription(conversationHash, subscription) {
        this.conversationClient.sendPushSubscription(conversationHash, subscription);
    }
    verifyPushSubscription(conversationHash, subscription) {
        this.conversationClient.verifyPushSubscription(conversationHash, subscription);
    }
    updateStaticMapImageMessage(conversationHash, messageId, data) {
        const message = this.findMessageById(messageId);
        if (message == null) {
            return;
        }
        this.imageService.blobToArray(data).then((dataArray) => {
            this.fileRepository.storeFile(dataArray).then(hash => {
                message.mediaFile = new MediaFile();
                message.mediaFile.filesId = hash;
                message.mediaFile.mimeType = data.type;
                this.messageRepository.updateMessage(conversationHash, message);
            });
        }).catch(err => console.error('Failed to store blob in file repository: ' + err.error));
    }
    addMessage(conversationHash, message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const inserted = this.insertMessageSorted(message);
            if (inserted) {
                this.clearSuggestionsFromPreviousAgentMessages(conversationHash);
                return this.messageRepository.storeMessage(conversationHash, message);
            }
            return '';
        });
    }
    updateOpenUrlMessage(messageId, conversationHash) {
        const index = this.findMessageIndexById(messageId);
        if (index === -1) {
            return;
        }
        const agentMessage = this.conversationMessages[index];
        this.messageRepository.updateMessage(conversationHash, agentMessage);
    }
    insertMessageSorted(currentMessage) {
        let i = this.conversationMessages.length;
        let insert = true;
        // iterate until a message with earlier or same timestamp is found (or not found at all)
        while (i > 0 && this.conversationMessages[i - 1].timestamp > currentMessage.timestamp) {
            i--;
        }
        let j = i - 1;
        // if the messages have the same timestamp, check that non of the already included hat the same ID
        while (j >= 0 && this.conversationMessages[j].timestamp === currentMessage.timestamp && insert) {
            insert = (this.conversationMessages[j].id !== currentMessage.id);
            j--;
        }
        if (insert) {
            this.conversationMessages.splice(i, 0, currentMessage);
        }
        return insert;
    }
    clearSuggestionsFromPreviousAgentMessages(conversationHash) {
        // we do not check the very last message in the array, so exclude the last element
        // while slicing
        this.conversationMessages.slice(0, this.conversationMessages.length - 1)
            .forEach(msg => {
            this.removeSuggestionsFromAgentMessage(conversationHash, msg);
        });
    }
    removeSuggestionsFromAgentMessage(conversationHash, message) {
        if (message.isMessageType(Party.AGENT)) {
            const agentMessage = message;
            if (agentMessage.hasSuggestions()) {
                agentMessage.removeSuggestions();
                this.messageRepository.updateMessage(conversationHash, agentMessage);
            }
        }
    }
    findMessageById(messageId) {
        const index = this.findMessageIndexById(messageId);
        if (index === -1) {
            return null;
        }
        return this.conversationMessages[index];
    }
    findMessageIndexById(messageId) {
        const messages = this.conversationMessages;
        let i = this.conversationMessages.length - 1;
        while (i >= 0) {
            const message = messages[i];
            if (message.id === messageId) {
                return i;
            }
            i--;
        }
        return -1;
    }
    isConnected() {
        return this.conversationClient.isConnected();
    }
    unsubscribe() {
        this.conversationClient.unsubscribeFromAgentMessage();
        this.conversationClient.unsubscribeFromClientMessageAck();
    }
}
