import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  private lastUpdateCheck = 0;

  constructor(private swUpdate: SwUpdate) {

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        console.log('Update available... triggering reload.');
        window.location.reload();
      });
    }

  }

  public checkForUpdate() {
    if (!this.swUpdate.isEnabled) { return; }
    const now = Date.now();
    // Once per hour
    if (now > this.lastUpdateCheck + 3600000) {
      this.lastUpdateCheck = now;
      console.log('Checking for update.');
      this.swUpdate.checkForUpdate();
    }
  }

}
