import { Type } from 'class-transformer';

import { AbstractMessage, MediaFile } from './abstract-message';
import { LatLng, SuggestedReply } from './suggestion';
import { Party } from './party';
import { serializeType } from './serialize-type';

export class ClientMessage extends AbstractMessage {

  @Type(serializeType(LatLng))
  public location: LatLng;
  @Type(serializeType(SuggestedReply))
  public suggestionResponse: SuggestedReply;

  constructor() {
    super();
    this.party = Party.CLIENT;
  }

  public static clientTextMessage(id: string, text: string, timestamp = Date.now()): ClientMessage {
    const clientMessage = new ClientMessage();
    clientMessage.id = id;
    clientMessage.text = text;
    clientMessage.timestamp = timestamp;
    return clientMessage;
  }

  public static clientMediaFileMessage(id: string, mediaFile: MediaFile, timestamp = Date.now()): ClientMessage {
    const clientMessage = new ClientMessage();
    clientMessage.id = id;
    clientMessage.mediaFile = mediaFile;
    clientMessage.timestamp = timestamp;
    return clientMessage;
  }

  public static clientSuggestionResponseMessage(id: string, suggestionResponse: SuggestedReply, timestamp = Date.now()): ClientMessage {
    const clientMessage = new ClientMessage();
    clientMessage.id = id;
    clientMessage.suggestionResponse = suggestionResponse;
    clientMessage.timestamp = timestamp;
    return clientMessage;
  }

  public static clientShareLocationMessage(id: string, location: LatLng, timestamp = Date.now()): ClientMessage {
    const clientMessage = new ClientMessage();
    clientMessage.id = id;
    clientMessage.location = location;
    clientMessage.timestamp = timestamp;
    return clientMessage;
  }

  public toString(): string {
    return `${this.constructor.name} (${super.toString()}, suggestionResponse: ${this.suggestionResponse})`;
  }
}

export class ClientMessageAck {
  public id: string;
  public timestamp: number;
}
