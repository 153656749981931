import Dexie from 'dexie';
import { MessageMapper } from '../conversation/model/message-mapper';
import { MediaFile } from '../conversation/model/abstract-message';
import { HashService } from '../shared/hash.service';
import { Party } from '../conversation/model/party';
import { AgentMessage } from '../conversation/model/agent-message';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: HashService
})
export class DatabaseService extends Dexie {
  constructor(private hashService: HashService) {
    super('rbm-conversations-v2');
    this.version(1).stores({
      messages: '&id, party, jsonMessage, timestamp, conversationHash'
    });
    this.version(2).stores({
      messages: '&id, party, jsonMessage, timestamp, conversationHash'
    }).upgrade(tx => {
      return tx.table('messages').toCollection().modify(messageEntry => {
        const messageContent = MessageMapper.fromJson(messageEntry.jsonMessage);
        if (messageContent.hasPersistentImage() && messageContent.mediaFile == null) {
          messageContent.mediaFile = new MediaFile();
          messageContent.mediaFile.mimeType = messageContent.persistentImage.type;
          messageContent.mediaFile.imageData = messageContent.persistentImage.data;
          delete messageContent.persistentImage;
          messageEntry.jsonMessage = MessageMapper.toJson(messageContent);
        }
      });
    });
    this.version(3).stores({
      messages: '&id, timestamp, conversationHash',
      files: '&id'
    }).upgrade(tx => {

      return tx.table('messages').toCollection().modify(messageEntry => {
        const messageContent = MessageMapper.fromJson(messageEntry.jsonMessage);

        let update = false;

        if (messageContent.mediaFile) {
          update = this.convertMediaFile(tx, messageContent.mediaFile) || update;
        }

        if (messageContent.isMessageType(Party.AGENT)) {
          const agentMessage: AgentMessage = <AgentMessage> messageContent;

          if (agentMessage.hasStandaloneCard() && agentMessage.standaloneCard.content.mediaFile) {

            update = this.convertMediaFile(tx, agentMessage.standaloneCard.content.mediaFile) || update;

          } else if (agentMessage.hasCarouselCard() && agentMessage.carouselCard.cards) {

            agentMessage.carouselCard.cards.forEach(async (entry) => {
              if (entry.mediaFile) {
                update = this.convertMediaFile(tx, entry.mediaFile) || update;
              }
            });
          }
        }

        if (update) {
          messageEntry.jsonMessage = MessageMapper.toJson(messageContent);
        }
      });

    });
  }

  private convertMediaFile(tx, mediaFile: MediaFile): boolean {
    let result = false;

    if (mediaFile.imageData) {
      const hash = this.storeData(tx, mediaFile.imageData);
      if (hash) {
        mediaFile.imageData = null;
        mediaFile.filesId = hash;
        result = true;
      }
    }

    if (mediaFile.thumbnailData) {
      const hash = this.storeData(tx, mediaFile.thumbnailData);
      if (hash) {
        mediaFile.thumbnailData = null;
        mediaFile.thumbnailFilesId = hash;
        result = true;
      }
    }

    return result;
  }

  private storeData(tx, data: number[]): string {
    const buffer = new Uint8Array(data);
    const hash = this.hashService.generateHash(buffer);

    tx.table('files').add({id: hash, data: data, size: data.length}).catch(e => {
      console.log('Insert ERROR ' + e + ' ' + hash);
    });

    return hash;
  }

}
