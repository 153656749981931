/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./brand-color.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./brand-color.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../conversation/services/brand-information.service";
var styles_BrandColorComponent = [i0.styles];
var RenderType_BrandColorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BrandColorComponent, data: {} });
export { RenderType_BrandColorComponent as RenderType_BrandColorComponent };
function View_BrandColorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["id", "brandHeader"]], [[1, "style", 2]], null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.domSanitizer.bypassSecurityTrustStyle((("--agent-color: " + _co.brandInformation.colorCode) + "; overflow: hidden; background-color: #f2f2f2;height:100%")); _ck(_v, 0, 0, currVal_0); }); }
function View_BrandColorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.brandInformation ? _co.brandInformation.translations.brand.loading : "loading ..."); _ck(_v, 1, 0, currVal_0); }); }
export function View_BrandColorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BrandColorComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["default_style_div", 2]], null, 0, null, View_BrandColorComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.brandInformation; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_BrandColorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-brand-color", [], null, null, null, View_BrandColorComponent_0, RenderType_BrandColorComponent)), i1.ɵdid(1, 245760, null, 0, i4.BrandColorComponent, [i2.ActivatedRoute, i5.Meta, i5.DomSanitizer, i6.BrandInformationService, i3.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BrandColorComponentNgFactory = i1.ɵccf("app-brand-color", i4.BrandColorComponent, View_BrandColorComponent_Host_0, {}, {}, []);
export { BrandColorComponentNgFactory as BrandColorComponentNgFactory };
