import { Component, Input, OnInit } from '@angular/core';
import { AbstractMessage } from '../../model/abstract-message';
import { SafeMediaUrlProvider } from '../../services/images/safe-media-url-provider';
import { SafeMediaUrlProviderFactory } from '../../services/images/safe-media-url-provider-factory.service';

@Component({
  selector: 'app-conversation-message-video',
  templateUrl: './media-video.component.html',
  styleUrls: ['./media-video.component.css']
})
export class MediaVideoComponent implements OnInit {

  @Input() message: AbstractMessage;
  private safeMediaUrlProvider: SafeMediaUrlProvider;

  constructor(private safeMediaUrlProviderFactory: SafeMediaUrlProviderFactory) { }

  ngOnInit () {
    this.safeMediaUrlProvider = this.safeMediaUrlProviderFactory.createProvider(this.message.mediaFile);
  }

  getDescription() {
    return (this.message.mediaFile.contentDescription == null) ? '' : this.message.mediaFile.contentDescription;
  }

  getVideoUrl() {
    return this.safeMediaUrlProvider.getMediaUrl();
  }

  getThumbnailUrl() {
    return this.safeMediaUrlProvider.getThumbnailUrl();
  }
}
