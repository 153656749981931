import { Injectable } from '@angular/core';
import { CreateCalendarEventAction } from '../model/suggestion';
import { CustomerBrandInformation } from '../model/customer-brand-information';
import * as moment from 'moment';
import utf8 from 'utf8';

@Injectable()
export class IcalService {

  constructor() { }

  public generateIcs(action: CreateCalendarEventAction, brandInfo: CustomerBrandInformation): string {
    let template = 'BEGIN:VCALENDAR\n';
    template += 'VERSION:2.0\n';
    template += 'PRODID:-//' + brandInfo.headline + '//LINK Conversations//EN\n';
    template += 'BEGIN:VEVENT\n';
    template += 'DTSTAMP:' + this.toICalcDateTime(moment().format()) + '\n';
    template += 'DTSTART:' + this.toICalcDateTime(action.startTime) + '\n';
    template += 'DTEND:' + this.toICalcDateTime(action.endTime) + '\n';
    template += 'SUMMARY:' + action.title + '\n';
    template += 'DESCRIPTION:' + action.description + '\n';
    template += 'END:VEVENT\n';
    template += 'END:VCALENDAR\n';
    return template;
  }

  public genertateDataLinkForIcs(action: CreateCalendarEventAction, brandInfo: CustomerBrandInformation): string {
    return 'data:text/calendar;base64,' + btoa(utf8.encode(this.generateIcs(action, brandInfo)));
  }

  private toICalcDateTime(datetime: string): string {
    return moment(datetime).utc().format('YYYYMMDDTHHmmss') + 'Z';
  }
}
