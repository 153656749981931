/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./brand-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./brand-header.component";
var styles_BrandHeaderComponent = [i0.styles];
var RenderType_BrandHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BrandHeaderComponent, data: {} });
export { RenderType_BrandHeaderComponent as RenderType_BrandHeaderComponent };
function View_BrandHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "logo"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "background-image": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "headline"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "infolink"], ["routerLink", "info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "separator"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hasMessages ? "brand-header" : "brand-header-no-messages"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, (("url(" + _co.brandInformation.iconUrl) + ")")); _ck(_v, 5, 0, currVal_1); var currVal_4 = "info"; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.brandInformation.headline; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.brandInformation.description; _ck(_v, 10, 0, currVal_3); var currVal_5 = _co.brandInformation.translations.brand.informationHeadline; _ck(_v, 13, 0, currVal_5); }); }
export function View_BrandHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BrandHeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.brandInformation; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BrandHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-brand-header", [], null, null, null, View_BrandHeaderComponent_0, RenderType_BrandHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i4.BrandHeaderComponent, [], null, null)], null, null); }
var BrandHeaderComponentNgFactory = i1.ɵccf("app-brand-header", i4.BrandHeaderComponent, View_BrandHeaderComponent_Host_0, { brandInformation: "brandInformation", hasMessages: "hasMessages" }, {}, []);
export { BrandHeaderComponentNgFactory as BrandHeaderComponentNgFactory };
