<div  class="suggestion-list">
  <ul>
    <li *ngFor="let suggestion of suggestions; let i = index" >
      <button  [attr.id]="getId(i)" [attr.aria-label]="suggestion.reply ? suggestion.reply.text : suggestion.action.text" tabindex="0" class="chipButton" (click)="click(suggestion)">
        <!-- workaround for vertical alignment -->
        <i class="material-icons vertical-align-middle">
          <mat-icon *ngIf="suggestion.action">{{suggestion.action.getType()}}</mat-icon>
        </i>
        {{suggestion.reply ? suggestion.reply.text : suggestion.action.text}}
      </button>
      <div class="separator" *ngIf="isNotLast(i)"></div>
    </li>
  </ul>
</div>
