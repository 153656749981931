import { Type } from 'class-transformer';
import { serializeType } from './serialize-type';

import { Party } from './party';
import { AbstractMessage } from './abstract-message';
import { SuggestionChip } from './suggestion';
import { CarouselCard, StandaloneCard } from './cards';

export class AgentMessage extends AbstractMessage {

  @Type(serializeType(SuggestionChip))
  public suggestions: Array<SuggestionChip>;
  @Type(serializeType(StandaloneCard))
  public standaloneCard: StandaloneCard;
  @Type(serializeType(CarouselCard))
  public carouselCard: CarouselCard;

  constructor() {
    super();
    this.party = Party.AGENT;
  }

  public static agentTextMessage(id: string, text: string, timestamp = Date.now()): AgentMessage {
    const agentMessage = new AgentMessage();
    agentMessage.id = id;
    agentMessage.text = text;
    agentMessage.timestamp = timestamp;
    return agentMessage;
  }

  public static agentTextSuggestionMessage(id: string, text: string, suggestions: Array<SuggestionChip>, timestamp = Date.now()): AgentMessage {
    const agentMessage = new AgentMessage();
    agentMessage.id = id;
    agentMessage.text = text;
    agentMessage.suggestions = suggestions;
    agentMessage.timestamp = timestamp;
    return agentMessage;
  }

  public hasSuggestions(): boolean {
    return (this.suggestions != null) && this.suggestions.length > 0;
  }

  public hasStandaloneCard(): boolean {
    return (this.standaloneCard != null);
  }

  public hasCarouselCard(): boolean {
    return (this.carouselCard != null);
  }

  public removeSuggestions() {
    this.suggestions = null;
  }

  public toString(): string {
    return `${this.constructor.name} (${super.toString()}, suggestions: ${this.suggestions}, standaloneCard: ${this.standaloneCard})`;
  }

}
