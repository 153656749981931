import * as tslib_1 from "tslib";
import Dexie from 'dexie';
import { DatabaseService } from '../../../database/database.service';
import { Party } from '../../model/party';
import { MessageMapper } from '../../model/message-mapper';
export class MessageRepositoryService {
    constructor(databaseService) {
        this.databaseService = databaseService;
        this.messages = this.databaseService.table('messages');
    }
    storeMessage(conversationHash, message) {
        const entity = new MessageEntity(message.id, message.party, MessageMapper.toJson(message), message.timestamp, conversationHash);
        return this.messages.add(entity);
    }
    updateMessage(conversationHash, message) {
        const entity = new MessageEntity(message.id, message.party, MessageMapper.toJson(message), message.timestamp, conversationHash);
        return this.messages.update(message.id, entity);
    }
    loadConversation(conversationHash) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const entities = yield this.messages.where('conversationHash')
                    .equals(conversationHash)
                    .sortBy('timestamp');
                return entities.map(e => MessageMapper.fromJson(e.jsonMessage));
            }
            catch (reason) {
                console.error(`MessageRepositoryService: could not loadConversation(${conversationHash}) because of: ${reason}`);
                return [];
            }
        });
    }
}
export class MessageEntity {
    constructor(id, party, jsonMessage, timestamp, conversationHash) {
        this.id = id;
        this.party = party;
        this.jsonMessage = jsonMessage;
        this.timestamp = timestamp;
        this.conversationHash = conversationHash;
    }
    toString() {
        return `MessageEntity (${Party[this.party]}, id: ${this.id}, timestamp: ${this.timestamp}, hash: ${this.conversationHash})`;
    }
}
