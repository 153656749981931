<div #messageComponent name = "messageComponent" class="main" *ngIf="hasNoCard()" [ngClass]="{'client': message.party==0, 'agent': message.party==1}">
  <div class="logo flex-item" *ngIf="message.party==1 && brandInformation" [ngStyle]="{'background-image': 'url(' + brandInformation.iconUrl + ')' }"
  routerLink="info"></div>
  <div class="container flex-item">
    <app-conversation-message-image [message]="message" *ngIf="message.hasImage()" (imageLoadedEvent)="imageLoadedEvent()"></app-conversation-message-image>
    <app-conversation-message-video [message]="message" *ngIf="message.hasVideo()" ></app-conversation-message-video>
    <app-conversation-message-audio [message]="message" [brandInformation]="brandInformation" *ngIf="message.hasAudio()" ></app-conversation-message-audio>
    <div *ngIf="message.hasImage() && message.hasText()" class="message-spacer"></div>
    <app-conversation-message-text [message]="message" *ngIf="message.hasText()"></app-conversation-message-text>
  </div>
  <div></div>
</div>
<div #messageComponent>
<app-rich-card [message]="message" *ngIf="message.hasStandaloneCard()"></app-rich-card>
<app-carousel [message]="message" *ngIf="message.hasCarouselCard()"></app-carousel>
</div>
<div class="datemarker" *ngIf="hasNoCard()" [ngClass]="{'date-client': message.party==0, 'date-agent': message.party==1}">{{message.timestamp | amLocale:getLocale() | amCalendar }}</div>
<div class="main suggestion" *ngIf="hasSuggestions()">
  <div class="container flex-item">
    <app-vanishing-suggestion [messageId] = "getMessageId()" [suggestions]="getSuggestions()"></app-vanishing-suggestion>
  </div>
  <div class="message-spacer"></div>
</div>
