import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeMediaUrlProvider } from './safe-media-url-provider';
import { MediaFile } from '../../model/abstract-message';
import { FileCacheService } from '../../../shared/file-cache.service';

@Injectable()
export class SafeMediaUrlProviderFactory {

  constructor(private domSanitizer: DomSanitizer, private fileCacheService: FileCacheService) { }

  public createProvider(mediaFile: MediaFile): SafeMediaUrlProvider {
    return new SafeMediaUrlProvider(mediaFile, this.domSanitizer, this.fileCacheService);
  }
}
