<div class="submit-form" >

  <div *ngIf="errorMessage" class="imageError">
    {{ errorMessage }}
  </div>
  <div class="image" *ngIf="previewImage">
    <div [ngClass]="{'upload-overlay': true, 'image-upload-active': uploadProgressValue != null}">
      <img #previewImageElement [src]="previewImage">
      <div *ngIf="uploadProgressValue == null" class="abort" (click)="cancelImage()">
        <i class="material-icons">cancel</i>
      </div>
    </div>
    <div *ngIf="uploadProgressValue != null" class="upload-label">{{brandInformation ? brandInformation.translations.conversation.sendMessage.sending : 'Sending...'}}</div>
    <mat-progress-bar *ngIf="uploadProgressValue != null" mode="determinate" [value]="uploadProgressValue"></mat-progress-bar>
  </div>

  <form  id="messageForm" #messageForm="ngForm" (ngSubmit)="submitMessage()">

    <input id="textInput" [ngClass]="{'noCursor': showCursor === false}"  appDetectFocus (onFocusEvent)="inputFocusEvent($event)" (touchmove)="inputMouseMove($event)" (mousemove)="inputMouseMove($event)" #msgInput name="inputMessage" type="text" [placeholder]="brandInformation ? brandInformation.translations.conversation.sendMessage.messageInputPlaceholder : 'Type a message'"
          autocomplete="off" ngModel [disabled]="isFormDisabled">
    <button type="button" [disabled]="isFormDisabled" (click)="selectImage()">
      <i class="material-icons select-image">attachment</i>
    </button>
    <button type="submit" [disabled]="isFormDisabled || !isFormValid()">
      <i class="material-icons">send</i>
    </button>
  </form>
</div>

<div id="imgform">
  <form>
    <input #fileInput (change)="onFileChange($event)" type="file" [accept]="SUPPORTED_MIMETYPES.join(',')">
    <input disabled type="submit">
  </form>
</div>
