import * as tslib_1 from "tslib";
import { Type } from 'class-transformer';
import { serializeType } from './serialize-type';
import { Party } from './party';
import { AbstractMessage } from './abstract-message';
import { SuggestionChip } from './suggestion';
import { CarouselCard, StandaloneCard } from './cards';
export class AgentMessage extends AbstractMessage {
    constructor() {
        super();
        this.party = Party.AGENT;
    }
    static agentTextMessage(id, text, timestamp = Date.now()) {
        const agentMessage = new AgentMessage();
        agentMessage.id = id;
        agentMessage.text = text;
        agentMessage.timestamp = timestamp;
        return agentMessage;
    }
    static agentTextSuggestionMessage(id, text, suggestions, timestamp = Date.now()) {
        const agentMessage = new AgentMessage();
        agentMessage.id = id;
        agentMessage.text = text;
        agentMessage.suggestions = suggestions;
        agentMessage.timestamp = timestamp;
        return agentMessage;
    }
    hasSuggestions() {
        return (this.suggestions != null) && this.suggestions.length > 0;
    }
    hasStandaloneCard() {
        return (this.standaloneCard != null);
    }
    hasCarouselCard() {
        return (this.carouselCard != null);
    }
    removeSuggestions() {
        this.suggestions = null;
    }
    toString() {
        return `${this.constructor.name} (${super.toString()}, suggestions: ${this.suggestions}, standaloneCard: ${this.standaloneCard})`;
    }
}
tslib_1.__decorate([
    Type(serializeType(SuggestionChip)),
    tslib_1.__metadata("design:type", Array)
], AgentMessage.prototype, "suggestions", void 0);
tslib_1.__decorate([
    Type(serializeType(StandaloneCard)),
    tslib_1.__metadata("design:type", StandaloneCard)
], AgentMessage.prototype, "standaloneCard", void 0);
tslib_1.__decorate([
    Type(serializeType(CarouselCard)),
    tslib_1.__metadata("design:type", CarouselCard)
], AgentMessage.prototype, "carouselCard", void 0);
