import { NgModule, Optional, SkipSelf } from '@angular/core';

import {DatabaseService} from './database.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    DatabaseService,
  ],
  bootstrap: []
})
export class DatabaseModule {
  constructor(@Optional() @SkipSelf() parentModule: DatabaseModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
