<mat-card [ngClass]="cardWidth">
  <div class="card-orientation" [ngClass]="getOrientation()">
    <div *ngIf="showImage()"
         class="card-orientation image" [ngClass]="getOrientation()"
         [style.background-image]="getThumbnailOrImageAsStyle() | async"
         (click)="openImageOverlay()">
    </div>
    <div *ngIf="showVideoThumbnail()"
         class="card-orientation image thumbnail" [ngClass]="getOrientation()"
         [style.background-image]="getThumbnailAsStyle() | async"
         (click)="onThumbnailClicked()">
      <i class="material-icons">play_circle_outline</i>
    </div>
    <video #videoPlayer [hidden]="hideVideoElement()" preload="none" controls="controls" (ended)="onEnded()">
      <source [src]="getMediaUrl()" [type]="getMediaMimeType()">
    </video>

    <mat-card-content>
      <div>
        <div class="title description" [ngClass]="{'arabic-text': isPercentageArabicTitle()}">{{ cardContent.title }}</div>
        <p class="description" [ngClass]="{'arabic-text': isPercentageArabicDescription()}">{{ cardContent.description }}</p>
      </div>
      <div *ngIf="hasSuggestions()">
        <app-suggestion [messageId]="messageId" [suggestions]="cardContent.suggestions"></app-suggestion>
      </div>
    </mat-card-content>
  </div>
</mat-card>
