/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-bar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/material/menu";
import * as i9 from "@angular/cdk/overlay";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/common";
import * as i12 from "./top-bar.component";
import * as i13 from "../../conversation/services/stomp/conversation-client.service";
import * as i14 from "@angular/router";
var styles_TopBarComponent = [i0.styles];
var RenderType_TopBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopBarComponent, data: {} });
export { RenderType_TopBarComponent as RenderType_TopBarComponent };
function View_TopBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["mat-icon-button", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.click() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatAnchor_0, i2.RenderType_MatAnchor)), i1.ɵdid(1, 180224, null, 0, i3.MatAnchor, [i4.FocusMonitor, i1.ElementRef, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_back"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled ? (0 - 1) : (i1.ɵnov(_v, 1).tabIndex || 0)); var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); var currVal_2 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_3 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 3).inline; var currVal_5 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_4, currVal_5); }); }
function View_TopBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "button", [["aria-haspopup", "true"], ["class", "mat-menu-trigger"], ["mat-icon-button", ""]], [[1, "aria-expanded", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 1196032, null, 0, i8.MatMenuTrigger, [i9.Overlay, i1.ElementRef, i1.ViewContainerRef, i8.MAT_MENU_SCROLL_STRATEGY, [2, i8.MatMenu], [8, null], [2, i10.Directionality], i4.FocusMonitor], { menu: [0, "menu"] }, null), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["more_vert"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.menu; _ck(_v, 1, 0, currVal_3); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).menuOpen || null); var currVal_1 = (i1.ɵnov(_v, 2).disabled || null); var currVal_2 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 4).inline; var currVal_5 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_4, currVal_5); }); }
function View_TopBarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Connecting..."]))], null, null); }
function View_TopBarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["STAGING BACKEND"]))], null, null); }
function View_TopBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "error-msg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarComponent_4)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarComponent_5)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isConnected(); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isLiveBackend(); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TopBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "navbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "spacer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "spacer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarComponent_2)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarComponent_3)), i1.ɵdid(11, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.backArrowVisible && _co.backLink); _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.menu; _ck(_v, 9, 0, currVal_2); var currVal_3 = (!_co.isConnected() || !_co.isLiveBackend()); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 6, 0, currVal_1); }); }
export function View_TopBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-bar", [], null, null, null, View_TopBarComponent_0, RenderType_TopBarComponent)), i1.ɵdid(1, 49152, null, 0, i12.TopBarComponent, [i13.ConversationClientService, i1.NgZone, i14.Router], null, null)], null, null); }
var TopBarComponentNgFactory = i1.ɵccf("app-top-bar", i12.TopBarComponent, View_TopBarComponent_Host_0, { backLink: "backLink", title: "title", menu: "menu" }, {}, []);
export { TopBarComponentNgFactory as TopBarComponentNgFactory };
