import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { DatabaseService } from '../database/database.service';
import { HashService } from './hash.service';

@Injectable()
export class FileRepositoryService {

  private files: Dexie.Table<FileEntry, string>;

  constructor(private databaseService: DatabaseService, private hashService: HashService) {
    this.files = this.databaseService.table('files');
  }

  public loadFile(id: string): Promise<FileEntry> {
    return this.files.get(id);
  }

  /** @return Promise . Primary key is the hash of the file content **/
  public storeFile(data: Uint8Array): Promise<string> {
    return new Promise((resolve, reject) => {
      if (data == null || data.length === 0) {
        resolve(null);
        return;
      }
      const hash = this.hashService.generateHash(data);
      const file = new FileEntry(hash, data, data.length);
      this.files.add(file).then( (key) => resolve(key) ).catch( (e) => resolve(hash));
    });
  }
}

export class FileEntry {
  id: string;
  data: Uint8Array;
  size: number;

  constructor(id: string, data: Uint8Array, size: number) {
    this.id = id;
    this.data = data;
    this.size = size;
  }

  public toString(): string {
    return `FileEntity (id: ${this.id}, size: ${this.size})`;
  }
}
