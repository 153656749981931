import { RxStomp } from '@stomp/rx-stomp';
import * as i0 from "@angular/core";
export class RxStompService extends RxStomp {
    constructor() {
        super();
    }
    logMessage(str) {
        if (str) {
            console.log('RPC Server: ', new Date(), str);
        }
    }
}
RxStompService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RxStompService_Factory() { return new RxStompService(); }, token: RxStompService, providedIn: "root" });
