import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlEscape'
})
export class HtmlEscapePipe implements PipeTransform {

  private static tagsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;'
  };

  private static replaceTag(tag) {
    return HtmlEscapePipe.tagsToReplace[tag] || tag;
  }

  private static safe_tags_replace(str) {
    return str.replace(/[&<>]/g, HtmlEscapePipe.replaceTag);
  }

  transform(value: any, args?: any): any {
    return HtmlEscapePipe.safe_tags_replace(value);
  }

}
