import { Component, Input } from '@angular/core';

import { AbstractMessage } from '../../model/abstract-message';

@Component({
  selector: 'app-conversation-message-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent {

  private static readonly PERCENT: number = 50;

  @Input() message: AbstractMessage;

  private arabicExp: RegExp;

  constructor() {
    this.arabicExp = new RegExp('^[\u0600-\u06FF]');
  }

  public isArabic(): boolean {
    return this.arabicExp.test(this.message.text);
  }

  public isPercentageArabic(): boolean {
    let count = 0;
    for (let i = 0; i < this.message.text.length; i++) {
      if (this.arabicExp.test(this.message.text[i])) {
        count++;
      }
    }
    return count >= ((this.message.text.length * TextComponent.PERCENT) / 100);
  }
}
