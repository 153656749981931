import { Directive, HostListener, HostBinding, Input, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appDetectFocus]',
  exportAs: 'detectFocus'
})
export class DetectFocusDirective {
  private _isFocus = false;

  @Output() onFocusEvent = new EventEmitter();

  constructor() { }

  @HostListener('blur')
  onBlur() {
    this._isFocus = false;
    this.onFocusEvent.emit(false);
  }


  @HostListener('focus')
  onFocusIn() {
    this._isFocus = true;
    this.onFocusEvent.emit(true);
  }

}
