/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vanishing-suggestion.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./vanishing-suggestion.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../services/message.service";
import * as i8 from "../../../../shared/map-location-picker.service";
import * as i9 from "../../../services/ical.service";
import * as i10 from "../../../services/brand-information.service";
import * as i11 from "../../../services/window-ref.service";
import * as i12 from "../../../services/static-map.service";
import * as i13 from "../../../services/http-service";
var styles_VanishingSuggestionComponent = [i0.styles];
var RenderType_VanishingSuggestionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VanishingSuggestionComponent, data: {} });
export { RenderType_VanishingSuggestionComponent as RenderType_VanishingSuggestionComponent };
function View_VanishingSuggestionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.action.getType(); _ck(_v, 2, 0, currVal_2); }); }
function View_VanishingSuggestionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "chipButton"], ["tabindex", "0"], ["type", "button"]], [[1, "id", 0], [1, "aria-label", 0], [8, "name", 0], [1, "data-index", 0]], [[null, "click"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (_co.click(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VanishingSuggestionComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_4 = _v.context.$implicit.action; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getId(_v.context.index); var currVal_1 = (_v.context.$implicit.reply ? _v.context.$implicit.reply.text : _v.context.$implicit.action.text); var currVal_2 = i1.ɵinlineInterpolate(1, "", (_v.context.$implicit.reply ? _v.context.$implicit.reply.text : _v.context.$implicit.action.text), ""); var currVal_3 = _v.context.index; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = (_v.context.$implicit.reply ? _v.context.$implicit.reply.text : _v.context.$implicit.action.text); _ck(_v, 3, 0, currVal_5); }); }
function View_VanishingSuggestionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "chip-button-wrapper"], ["name", "suggestionsWrapper"]], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.mouseDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VanishingSuggestionComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.suggestions; var currVal_1 = _co.trackBySuggestionId; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_VanishingSuggestionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_VanishingSuggestionComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSuggestion; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_VanishingSuggestionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vanishing-suggestion", [], null, [[null, "keydown"], [null, "touchstart"], [null, "touchend"], [null, "mousedown"], [null, "click"], [null, "mouseup"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onWindowEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onWindowEvent($event) !== false);
        ad = (pd_2 && ad);
    } if (("mousedown" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onWindowEvent($event) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).onWindowEvent($event) !== false);
        ad = (pd_4 && ad);
    } if (("mouseup" === en)) {
        var pd_5 = (i1.ɵnov(_v, 1).onWindowEvent($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, View_VanishingSuggestionComponent_0, RenderType_VanishingSuggestionComponent)), i1.ɵdid(1, 12697600, null, 0, i5.VanishingSuggestionComponent, [i6.ActivatedRoute, i7.MessageService, i8.MapLocationPickerService, i9.IcalService, i10.BrandInformationService, i11.WindowRefService, i12.StaticMapService, i13.HttpService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VanishingSuggestionComponentNgFactory = i1.ɵccf("app-vanishing-suggestion", i5.VanishingSuggestionComponent, View_VanishingSuggestionComponent_Host_0, { messageId: "messageId", suggestions: "suggestions" }, {}, []);
export { VanishingSuggestionComponentNgFactory as VanishingSuggestionComponentNgFactory };
