import {Component, NgZone, OnInit} from '@angular/core';

import {isNullOrUndefined} from 'util';
import {CustomerBrandInformation} from '../conversation/model/customer-brand-information';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.css'],
})
export class ConversationListComponent implements OnInit {

  conversations: Array<ConversationMetaData> = new Array();
  status: string;


  constructor(private route: ActivatedRoute, private ngZone: NgZone, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.status = params['status'];
    });

    const conversations: Array<string> = JSON.parse(localStorage.getItem('conversartion_list'));
    if (!isNullOrUndefined(conversations)) {
      conversations.forEach(conversationHash => {
        const brandInfoJson = localStorage.getItem('brand_' + conversationHash);
        if (!isNullOrUndefined(brandInfoJson)) {
          const agentInfo = CustomerBrandInformation.fromJson(brandInfoJson);
          this.conversations.push(new ConversationMetaData(conversationHash, agentInfo));
        }
      });
    }
  }

  click(conversationHash: string) {
    this.ngZone.run (() => this.router.navigate([this.router.url + '/' + conversationHash]));
  }
}



export class ConversationMetaData {
  brandInfo: CustomerBrandInformation;
  conversationHash: string;

  constructor (conversationHash: string, brandInfo: CustomerBrandInformation) {
    this.brandInfo = brandInfo;
    this.conversationHash = conversationHash;
  }
}
