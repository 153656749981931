import { Component, Input, OnInit } from '@angular/core';
import { CustomerBrandInformation } from '../../conversation/model/customer-brand-information';

@Component({
  selector: 'app-brand-images',
  templateUrl: './brand-images.component.html',
  styleUrls: ['./brand-images.component.css']
})
export class BrandImagesComponent implements OnInit {

  @Input() brandInformation: CustomerBrandInformation = new CustomerBrandInformation();

  constructor() {}

  ngOnInit() {
  }

}
