import { SwUpdate } from '@angular/service-worker';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
export class UpdateService {
    constructor(swUpdate) {
        this.swUpdate = swUpdate;
        this.lastUpdateCheck = 0;
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                console.log('Update available... triggering reload.');
                window.location.reload();
            });
        }
    }
    checkForUpdate() {
        if (!this.swUpdate.isEnabled) {
            return;
        }
        const now = Date.now();
        // Once per hour
        if (now > this.lastUpdateCheck + 3600000) {
            this.lastUpdateCheck = now;
            console.log('Checking for update.');
            this.swUpdate.checkForUpdate();
        }
    }
}
UpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateService_Factory() { return new UpdateService(i0.ɵɵinject(i1.SwUpdate)); }, token: UpdateService, providedIn: "root" });
