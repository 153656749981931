import { Component, OnInit, OnDestroy, Inject, HostListener } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { CustomerBrandInformation } from '../conversation/model/customer-brand-information';
import { ActivatedRoute } from '@angular/router';
import {DOCUMENT} from '@angular/common';
import { BrandInformationService } from '../conversation/services/brand-information.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-brand-color',
  templateUrl: './brand-color.component.html',
  styleUrls: ['./brand-color.component.css']
})
export class BrandColorComponent implements OnInit, OnDestroy {

  readonly THEME_COLOR = 'theme-color';
  readonly DEFAULT_BRAND_COLOR = '#036479';
  private oldColor: string = null || null;


  conversationHash: string;

  brandInformation: CustomerBrandInformation;

  constructor(private route: ActivatedRoute,
              private meta: Meta,
              public domSanitizer: DomSanitizer,
              private brandInformationService: BrandInformationService,
              @Inject(DOCUMENT) private document: any) {
  }

  ngOnInit() {
    if (isNullOrUndefined(this.conversationHash)) {
      this.conversationHash = this.route.snapshot.paramMap.get('hash');
    }
    if (this.conversationHash) {
      this.loadBrandInformation();
    }
  }

  ngOnDestroy() {
    if (isNullOrUndefined(this.oldColor)) {
      this.meta.removeTag(this.THEME_COLOR);
    } else {
      this.meta.updateTag({name: this.THEME_COLOR, content: this.oldColor});
    }
  }

  private loadBrandInformation() {
    this.brandInformationService.getBrandInformation(this.conversationHash).then(
      information => {
        if (information && information.colorCode) {
          const meta: HTMLMetaElement = this.meta.getTag('name="' + this.THEME_COLOR + '"');
          if (!isNullOrUndefined(meta) && !isNullOrUndefined(meta.content)) {
            this.oldColor = meta.content;
          }
          this.meta.updateTag({name: this.THEME_COLOR, content: information.colorCode});
          this.brandInformation = information;
        }
      },
      error => {
        console.log('Error loading brand color:', error);
        const information = new CustomerBrandInformation();
        information.colorCode = this.DEFAULT_BRAND_COLOR;
        this.brandInformation = information;
      });
  }

}

