import { Component, OnInit, Input } from '@angular/core';
import { AbstractMessage } from '../../model/abstract-message';
import { CustomerBrandInformation } from '../../model/customer-brand-information';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-conversation-message-audio',
  templateUrl: './media-audio.component.html',
  styleUrls: ['./media-audio.component.css']
})
export class MediaAudioComponent {

  @Input() message: AbstractMessage;
  @Input() brandInformation: CustomerBrandInformation;

  constructor() { }

  getDescription() {
    return (isNullOrUndefined(this.message.mediaFile.contentDescription)) ? '' : this.message.mediaFile.contentDescription;
  }
}
