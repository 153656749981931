/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-overlay.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./image-overlay.component";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "../image-overlay.token";
var styles_ImageOverlayComponent = [i0.styles];
var RenderType_ImageOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageOverlayComponent, data: {} });
export { RenderType_ImageOverlayComponent as RenderType_ImageOverlayComponent };
export function View_ImageOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "closer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["highlight_off"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 4, 0, currVal_0); }); }
export function View_ImageOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-overlay", [], null, null, null, View_ImageOverlayComponent_0, RenderType_ImageOverlayComponent)), i1.ɵdid(1, 114688, null, 0, i2.ImageOverlayComponent, [i3.OverlayRef, i4.FILE_PREVIEW_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageOverlayComponentNgFactory = i1.ɵccf("app-image-overlay", i2.ImageOverlayComponent, View_ImageOverlayComponent_Host_0, {}, {}, []);
export { ImageOverlayComponentNgFactory as ImageOverlayComponentNgFactory };
