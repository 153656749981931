/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-video.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./media-video.component";
import * as i4 from "../../services/images/safe-media-url-provider-factory.service";
var styles_MediaVideoComponent = [i0.styles];
var RenderType_MediaVideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaVideoComponent, data: {} });
export { RenderType_MediaVideoComponent as RenderType_MediaVideoComponent };
export function View_MediaVideoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "video"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "video", [["controls", "controls"], ["preload", "none"]], [[8, "poster", 4]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "source", [], [[8, "src", 4], [8, "type", 0]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDescription(); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.getThumbnailUrl())); _ck(_v, 1, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.getVideoUrl())); var currVal_3 = _co.message.mediaFile.mimeType; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
export function View_MediaVideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-conversation-message-video", [], null, null, null, View_MediaVideoComponent_0, RenderType_MediaVideoComponent)), i1.ɵdid(1, 114688, null, 0, i3.MediaVideoComponent, [i4.SafeMediaUrlProviderFactory], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MediaVideoComponentNgFactory = i1.ɵccf("app-conversation-message-video", i3.MediaVideoComponent, View_MediaVideoComponent_Host_0, { message: "message" }, {}, []);
export { MediaVideoComponentNgFactory as MediaVideoComponentNgFactory };
