import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InformationPageComponent } from './information-page.component';
import { BrandImagesComponent } from './brand-images/brand-images.component';
import { ActionsComponent } from './actions/actions.component';
import { DetailsComponent } from './details/details.component';
import { SharedModule } from '../shared/shared.module';
import { LinkyModule } from 'ngx-linky';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LinkyModule
  ],
  declarations: [
    InformationPageComponent,
    BrandImagesComponent,
    ActionsComponent,
    DetailsComponent
  ]
})
export class InformationPageModule {
}
