import { Component, Input, OnInit, OnChanges, AfterViewInit, ElementRef} from '@angular/core';
import { MapLocationPickerService } from '../../../../shared/map-location-picker.service';
import { IcalService } from '../../../services/ical.service';
import { StaticMapService } from '../../../services/static-map.service';
import { CreateCalendarEventAction, DialAction, LatLng, OpenUrlAction, SuggestedAction, SuggestionChip, ViewLocationAction } from '../../../model/suggestion';
import { UUID } from 'angular2-uuid';
import { WindowRefService } from '../../../services/window-ref.service';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { MessageService } from '../../../services/message.service';
import { BrandInformationService } from '../../../services/brand-information.service';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../services/http-service';

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.css']
})
export class SuggestionComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() suggestions: Array<SuggestionChip>;
  @Input() messageId: string;

  conversationHash: string;

  readonly _window: Window;


  constructor(private route: ActivatedRoute,
              protected messageService: MessageService,
              private mapLocation: MapLocationPickerService,
              private icalService: IcalService,
              private brandInfoService: BrandInformationService,
              private windowRefService: WindowRefService,
              private staticMapService: StaticMapService,
              private httpService: HttpService,
               private elementRef: ElementRef) {

    this._window = this.windowRefService.getWindowRef();
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.conversationHash = this.route.snapshot.paramMap.get('hash');
  }

  public click(suggestion: SuggestionChip) {
    if (this.isConnected()) {
      if (suggestion.isReply()) {
        this.messageService.sendSuggestionResponseClientMessage(this.conversationHash, UUID.UUID(), Date.now(), suggestion.reply);
      } else {
        this.suggestedActionOnClick(suggestion.action);
      }
    }
  }

  public getId(index): string {
    return this.messageId + '_' + index;
  }

  ngOnChanges() {
  }

  suggestedActionOnClick(suggestion: SuggestedAction): void {
    this.messageService.sendSuggestionClickedEventMessage(this.conversationHash, UUID.UUID(), Date.now(), suggestion);
    if (!isNullOrUndefined(suggestion.dialAction)) {
      this.dialActionOnClick(suggestion.dialAction);
    } else if (!isNullOrUndefined(suggestion.openUrlAction)) {
      this.openUrlActionOnClick(suggestion.openUrlAction);
    } else if (!isNullOrUndefined(suggestion.shareLocationAction)) {
      this.shareLocationActionOnClick();
    } else if (!isNullOrUndefined(suggestion.viewLocationAction)) {
      this.viewLocationActionOnClick(suggestion.viewLocationAction);
    } else if (!isNullOrUndefined(suggestion.createCalendarEventAction)) {
      this.createCalendarEventActionOnClick(suggestion.createCalendarEventAction);
    }
  }

  public isConnected(): boolean {
    return this.messageService.isConnected();
  }

  private dialActionOnClick(action: DialAction): void {
    this._window.location.href = 'tel:' + action.phoneNumber;
  }

  private openUrlActionOnClick(action: OpenUrlAction): void {
    if (!action.request) {
    this._window.open(action.url, '_blank');
    } else {
      const win: Window = this._window.open('', '_blank');
      this.httpService.getHttp().post(action.url, null, {responseType: 'text'  as 'json'})
      .subscribe(
        data => {
         action.url = data.toString();
         if (win) {
          win.location.href = action.url;
         }
         action.request = false;
         this.messageService.updateOpenUrlMessage(this.messageId, this.conversationHash);
        },
        error => {
          if (win) {
            win.close();
          }
        },
        () => {
        });
      }
  }

  private shareLocationActionOnClick(): void {
    this.brandInfoService.getBrandInformation(this.conversationHash).then(brandInformation => {
      // Workaround to fix visibility of Googles Search Result box
      window.scrollTo(0, 0);
      this.mapLocation.open(brandInformation, this.shareLocationActionCallback);
    });
  }

  shareLocationActionCallback = (location: LatLng, address: string) => {
    this.handleShareLocationAction(location, address);
  };

  protected handleShareLocationAction(location: LatLng, address: string) {
    console.log('share location result:' + location + ' address:' + address);
    if (!isNullOrUndefined(location)) {
      const messageId: string = UUID.UUID();
      this.messageService.sendShareLocationClientMessage(this.conversationHash, messageId, Date.now(), location, address);
      this.staticMapService.getMapImage(location).then(res => {
        this.messageService.updateStaticMapImageMessage(this.conversationHash, messageId, res);
      });
    }
    // Workaround to fix visibility of Googles Search Result box
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }

  private viewLocationActionOnClick(action: ViewLocationAction): void {
    if (!isNullOrUndefined(action.latLong)) {
      // TODO label is ignored here. should be appended to URL
      this._window.open(environment.googlemaps.url + action.latLong.latitude + ',' + action.latLong.longitude);
    } else if (action.query) {
      this._window.open(environment.googlemaps.url + action.query);
    }
  }

  private createCalendarEventActionOnClick(action: CreateCalendarEventAction): void {
    this.brandInfoService.getBrandInformation(this.conversationHash).then(brandInformation => {
      this._window.location.href = this.icalService.genertateDataLinkForIcs(action, brandInformation);
    });
  }
  public isNotLast(index: number) {
    return this.suggestions.length > (index + 1);
  }
}
