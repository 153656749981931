import * as tslib_1 from "tslib";
import { Type } from 'class-transformer';
import { serializeType } from './serialize-type';
import { isNullOrUndefined } from 'util';
export class LatLng {
    constructor() { }
    static latLng(latitude, longitude) {
        const action = new LatLng();
        action.latitude = latitude;
        action.longitude = longitude;
        return action;
    }
    toString() {
        return `LatLng: ${this.latitude}/${this.longitude}`;
    }
}
export class SuggestedReply {
    constructor() { }
    static suggestedReply(text, postbackData) {
        const suggestedReply = new SuggestedReply();
        suggestedReply.text = text;
        suggestedReply.postbackData = postbackData;
        return suggestedReply;
    }
    toString() {
        return `${this.text}`;
    }
}
export class ShareLocationAction {
    static create() {
        return new ShareLocationAction();
    }
}
export class DialAction {
    constructor() { }
    static create(phoneNumber) {
        const action = new DialAction();
        action.phoneNumber = phoneNumber;
        return action;
    }
}
export class ViewLocationAction {
    constructor() { }
    static create(latitude, longitude, label, query) {
        const action = new ViewLocationAction();
        if (latitude) {
            action.latLong = new LatLng();
            action.latLong.latitude = latitude;
            action.latLong.longitude = longitude;
        }
        if (label) {
            action.label = label;
        }
        if (query) {
            action.query = query;
        }
        return action;
    }
}
tslib_1.__decorate([
    Type(serializeType(LatLng)),
    tslib_1.__metadata("design:type", LatLng)
], ViewLocationAction.prototype, "latLong", void 0);
export class CreateCalendarEventAction {
    constructor() { }
    static create(startTime, endTime, title, description) {
        const action = new CreateCalendarEventAction();
        action.startTime = startTime;
        action.endTime = endTime;
        action.title = title;
        action.description = description;
        return action;
    }
}
export class OpenUrlAction {
    constructor() { }
    static create(url, request) {
        const action = new OpenUrlAction();
        action.url = url;
        action.request = request;
        return action;
    }
}
export class SuggestedAction extends SuggestedReply {
    constructor() {
        super();
    }
    static openUrlAction(text, request, postbackData, url) {
        const suggestedAction = SuggestedAction.baseSuggestedAction(text, postbackData);
        suggestedAction.openUrlAction = OpenUrlAction.create(url, request);
        return suggestedAction;
    }
    static dialAction(text, postbackData, phoneNumber) {
        const suggestedAction = SuggestedAction.baseSuggestedAction(text, postbackData);
        suggestedAction.dialAction = DialAction.create(phoneNumber);
        return suggestedAction;
    }
    static viewLocationAction(text, postbackData, latitude, longitude, label, query) {
        const suggestedAction = SuggestedAction.baseSuggestedAction(text, postbackData);
        suggestedAction.viewLocationAction = ViewLocationAction.create(latitude, longitude, label, query);
        return suggestedAction;
    }
    static createCalendarEventAction(text, postbackData, startTime, endTime, title, description) {
        const suggestedAction = SuggestedAction.baseSuggestedAction(text, postbackData);
        suggestedAction.createCalendarEventAction = CreateCalendarEventAction.create(startTime, endTime, title, description);
        return suggestedAction;
    }
    static shareLocationAction(text, postbackData) {
        const suggestedAction = SuggestedAction.baseSuggestedAction(text, postbackData);
        suggestedAction.shareLocationAction = new ShareLocationAction();
        return suggestedAction;
    }
    static baseSuggestedAction(text, postbackData) {
        const suggestedAction = new SuggestedAction();
        suggestedAction.text = text;
        suggestedAction.postbackData = postbackData;
        return suggestedAction;
    }
    getType() {
        // do not edit the returned values. this are the icon names
        if (!isNullOrUndefined(this.shareLocationAction)) {
            return 'location_searching';
        }
        else if (!isNullOrUndefined(this.dialAction)) {
            return 'call';
        }
        else if (!isNullOrUndefined(this.openUrlAction)) {
            if (this.openUrlAction.request === undefined || this.openUrlAction.request === null) {
                return 'public';
            }
            else {
                return 'credit_card';
            }
        }
        else if (!isNullOrUndefined(this.viewLocationAction)) {
            return 'place';
        }
        else if (!isNullOrUndefined(this.createCalendarEventAction)) {
            return 'event';
        }
    }
    toString() {
        return `type: ` + this.getType();
    }
}
tslib_1.__decorate([
    Type(serializeType(DialAction)),
    tslib_1.__metadata("design:type", DialAction)
], SuggestedAction.prototype, "dialAction", void 0);
tslib_1.__decorate([
    Type(serializeType(ViewLocationAction)),
    tslib_1.__metadata("design:type", ViewLocationAction)
], SuggestedAction.prototype, "viewLocationAction", void 0);
tslib_1.__decorate([
    Type(serializeType(CreateCalendarEventAction)),
    tslib_1.__metadata("design:type", CreateCalendarEventAction)
], SuggestedAction.prototype, "createCalendarEventAction", void 0);
tslib_1.__decorate([
    Type(serializeType(OpenUrlAction)),
    tslib_1.__metadata("design:type", OpenUrlAction)
], SuggestedAction.prototype, "openUrlAction", void 0);
tslib_1.__decorate([
    Type(serializeType(ShareLocationAction)),
    tslib_1.__metadata("design:type", ShareLocationAction)
], SuggestedAction.prototype, "shareLocationAction", void 0);
export class SuggestionChip {
    constructor() {
    }
    static suggestedReplyChip(text, postbackData) {
        const suggestionChip = new SuggestionChip();
        suggestionChip.reply = SuggestedReply.suggestedReply(text, postbackData);
        return suggestionChip;
    }
    static suggestedActionChip(action) {
        const suggestionChip = new SuggestionChip();
        suggestionChip.action = action;
        return suggestionChip;
    }
    isReply() {
        return isNullOrUndefined(this.action);
    }
    toString() {
        return `{reply: '${this.reply}', action: '${this.action}'}`;
    }
}
tslib_1.__decorate([
    Type(serializeType(SuggestedReply)),
    tslib_1.__metadata("design:type", SuggestedReply)
], SuggestionChip.prototype, "reply", void 0);
tslib_1.__decorate([
    Type(serializeType(SuggestedAction)),
    tslib_1.__metadata("design:type", SuggestedAction)
], SuggestionChip.prototype, "action", void 0);
