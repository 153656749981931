<agm-map [latitude]="lat" [longitude]="lng" [zoom]="15" [streetViewControl]="false" (mapReady)="mapReady($event)" (mapClick)="placeMarker($event)">
  <agm-marker id="location" [latitude]="lat" [longitude]="lng" [markerDraggable]="true" (dragEnd)="placeMarker($event)">
  </agm-marker>
</agm-map>

<div class="form-group" id="bar">
  <input id="pac-input" class="pac controls" [placeholder]="brandInformation.translations.conversation.shareLocation.searchLocation" autocorrect="off" autocapitalize="off" spellcheck="off"
    type="text" #search [formControl]="searchControl">
</div>

<div id="detectingPosition" *ngIf="detectingPosition">
  <mat-spinner [diameter]="24"></mat-spinner>
  <div>{{brandInformation.translations.conversation.shareLocation.detectingPosition}}</div>
</div>

<div id="bottom-bar">
  <button mat-raised-button class="pac controls" [disabled]="!locationSelected" (click)="applyLocation()">{{brandInformation.translations.conversation.shareLocation.shareLocationButton}}</button>
  <button mat-raised-button class="pac controls" (click)="close()">{{brandInformation.translations.conversation.shareLocation.abortButton}}</button>
</div>
