import * as tslib_1 from "tslib";
import { Party } from './party';
import { Transform, Type } from 'class-transformer';
import { serializeType } from './serialize-type';
import { ImageBase64Utils } from './image-base64-utils';
/** @deprecated Used ony for DB upgrade process */
export class PersistentImage {
}
tslib_1.__decorate([
    Transform(ImageBase64Utils.imageBytesToBase64, { toPlainOnly: true }),
    Transform(ImageBase64Utils.base64ToImageBytes, { toClassOnly: true }),
    tslib_1.__metadata("design:type", Array)
], PersistentImage.prototype, "data", void 0);
export class MediaFile {
    hasThumbnail() {
        return (this.thumbnailUrl != null || this.thumbnailFilesId != null);
    }
    isImage() {
        return this.mimeType.startsWith('image/');
    }
    isVideo() {
        return this.mimeType.startsWith('video/');
    }
    isAudio() {
        return this.mimeType.startsWith('audio/');
    }
    toString() {
        return `(url: '${this.fileUrl}', thumbnailUrl: '${this.thumbnailUrl}', fileId: '${this.filesId}', thumbnailFileId: '${this.thumbnailFilesId}', `
            + `contentDescription: ${this.contentDescription}, mimeType: '${this.mimeType}', contentLength: ${this.contentLength})`;
    }
}
tslib_1.__decorate([
    Transform(ImageBase64Utils.imageBytesToBase64, { toPlainOnly: true }),
    Transform(ImageBase64Utils.base64ToImageBytes, { toClassOnly: true }),
    tslib_1.__metadata("design:type", Array)
], MediaFile.prototype, "imageData", void 0);
tslib_1.__decorate([
    Transform(ImageBase64Utils.imageBytesToBase64, { toPlainOnly: true }),
    Transform(ImageBase64Utils.base64ToImageBytes, { toClassOnly: true }),
    tslib_1.__metadata("design:type", Array)
], MediaFile.prototype, "thumbnailData", void 0);
export class AbstractMessage {
    constructor() {
        this.hideFreeText = false;
    }
    getParty() {
        return Party[this.party];
    }
    isMessageType(party) {
        return this.party === party;
    }
    hasPersistentImage() {
        return this.persistentImage != null && this.persistentImage.type.startsWith('image/');
    }
    hasImage() {
        return this.hasMediaFile() && this.mediaFile.isImage();
    }
    hasVideo() {
        return this.hasMediaFile() && this.mediaFile.isVideo();
    }
    hasAudio() {
        return this.hasMediaFile() && this.mediaFile.isAudio();
    }
    hasMediaFile() {
        return this.mediaFile != null;
    }
    hasText() {
        return this.text != null;
    }
    hasStandaloneCard() {
        return false;
    }
    hasCarouselCard() {
        return false;
    }
    toString() {
        return `(id: '${this.id}', party: '${this.getParty()}', timestamp: ${this.timestamp}, text: '${this.text}', mediaFile: '${this.mediaFile}')`;
    }
}
tslib_1.__decorate([
    Type(serializeType(PersistentImage)),
    tslib_1.__metadata("design:type", PersistentImage)
], AbstractMessage.prototype, "persistentImage", void 0);
tslib_1.__decorate([
    Type(serializeType(MediaFile)),
    tslib_1.__metadata("design:type", MediaFile)
], AbstractMessage.prototype, "mediaFile", void 0);
