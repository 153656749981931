import { WindowRefService } from '../conversation/services/window-ref.service';
import { FileRepositoryService } from './file-repository.service';
import * as i0 from "@angular/core";
import * as i1 from "./file-repository.service";
import * as i2 from "../conversation/services/window-ref.service";
export class FileCacheService {
    constructor(fileRepositoryService, windowRefService) {
        this.fileRepositoryService = fileRepositoryService;
        this.windowRefService = windowRefService;
        this.cache = new Map();
        this._window = this.windowRefService.getWindowRef();
    }
    getBlobUrlForFileId(id, mimeType) {
        let cacheEntry = this.cache.get(id);
        if (cacheEntry == null) {
            cacheEntry = new Promise(resolve => {
                this.fileRepositoryService.loadFile(id).then((fileEntry) => {
                    const url = this.createBlobUrl(fileEntry.data, mimeType);
                    resolve(url);
                });
            });
            this.cache.set(id, cacheEntry);
        }
        return cacheEntry;
    }
    createBlobUrl(data, mimeType) {
        const blob = new Blob([data], { type: mimeType });
        return this._window.URL.createObjectURL(blob);
    }
    destroy() {
        this.cache.forEach((url) => {
            this._window.URL.revokeObjectURL(url);
        });
    }
}
FileCacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileCacheService_Factory() { return new FileCacheService(i0.ɵɵinject(i1.FileRepositoryService), i0.ɵɵinject(i2.WindowRefService)); }, token: FileCacheService, providedIn: "root" });
