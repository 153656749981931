// workaround for https://github.com/angular/angular/issues/20216
// TODO: remove when above issue gets fixed
// means: remove @Type(serializeType(SuggestedReply)) annotations with @Type(() => SuggestedReply)
// calling this method here won't be necessary

export function serializeType<T>(object: T) {
  return function () {
    return object;
  };
}

