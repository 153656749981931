import { ComponentRef, EventEmitter, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { MapLocationPickerComponent } from './map-location-picker/map-location-picker.component';
import { LatLng } from '../conversation/model/suggestion';
import { CustomerBrandInformation } from '../conversation/model/customer-brand-information';

interface MapLocationPickerConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
}

const DEFAULT_CONFIG: MapLocationPickerConfig = {
  hasBackdrop: false,
  backdropClass: 'dark-backdrop',
  panelClass: 'fullscreen-dialog'
};

@Injectable()
export class MapLocationPickerService {

  public onOverlayOpened: EventEmitter<any> = new EventEmitter();
  public onOverlayClosed: EventEmitter<any> = new EventEmitter();

  constructor(private overlay: Overlay, private injector: Injector) { }

  open(brandInformation: CustomerBrandInformation, callbackFunction: (value: LatLng, address: string) => void): OverlayRef {
    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(DEFAULT_CONFIG);

    // Create ComponentPortal that can be attached to a PortalHost
    // const filePreviewPortal = new ComponentPortal(MapLocationPickerComponent);
    // Attach ComponentPortal to PortalHost
    // overlayRef.attach(filePreviewPortal);
    this.attachDialogContainer(overlayRef, brandInformation, (value: LatLng, address: string) => {
      this.onOverlayClosed.emit(null);
      callbackFunction(value, address);
    });
    this.onOverlayOpened.emit(null);
    return overlayRef;
  }

  private createOverlay(config: MapLocationPickerConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: MapLocationPickerConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
  }

  private attachDialogContainer(overlayRef: OverlayRef, brandInformation: CustomerBrandInformation, callbackFunction: (value: LatLng, address: string) => void) {
    const injector = this.createInjector(overlayRef);

    const containerPortal = new ComponentPortal(MapLocationPickerComponent, null, injector);
    const containerRef: ComponentRef<MapLocationPickerComponent> = overlayRef.attach(containerPortal);
    containerRef.instance.callback = callbackFunction;
    containerRef.instance.brandInformation = brandInformation;
    return containerRef.instance;
  }

  private createInjector(overlayRef: OverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(OverlayRef, overlayRef);

    return new PortalInjector(this.injector, injectionTokens);
  }

}
