import { Component, Input } from '@angular/core';
import { AgentMessage } from '../../model/agent-message';

@Component({
  selector: 'app-rich-card',
  templateUrl: './rich-card.component.html',
  styleUrls: ['./rich-card.component.css']
})
export class RichCardComponent {

  @Input() message: AgentMessage;

}
