import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MomentModule } from 'ngx-moment';
import { ConversationRoutingModule } from './conversation-routing.module';

import { ConversationComponent } from './conversation.component';
import { MessageSubmitFormComponent } from './message-submit-form/message-submit-form.component';
import { MessageComponent } from './message/message.component';
import { TextComponent } from './message/text/text.component';
import { BrandHeaderComponent } from './brand-header/brand-header.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { MediaImageComponent } from './message/media-image/media-image.component';
import { MediaAudioComponent } from './message/media-audio/media-audio.component';
import { MediaVideoComponent } from './message/media-video/media-video.component';
import { RichCardComponent } from './message/rich-card/rich-card.component';
import { VanishingSuggestionComponent } from './message/suggestion/vanishing-suggestion/vanishing-suggestion.component';
import { SuggestionComponent } from './message/suggestion/suggestion/suggestion.component';
import { ImageOverlayComponent } from '../shared/image-overlay/image-overlay.component';
import { CarouselComponent } from './message/carousel/carousel.component';
import { CardContentComponent } from './message/card-content/card-content.component';
import { LinkyModule } from 'ngx-linky';
import { HtmlEscapePipe } from '../shared/pipes/html-escape.pipe';
import { DetectFocusDirective } from './message-submit-form/detect-focus-directive';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    MomentModule,
    MatChipsModule,
    NoopAnimationsModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatProgressBarModule,
    RouterModule,
    ConversationRoutingModule,
    SharedModule,
    LinkyModule,
    HttpClientModule
  ],
  declarations: [
    ConversationComponent,
    MessageSubmitFormComponent,
    MessageComponent,
    TextComponent,
    BrandHeaderComponent,
    MediaImageComponent,
    MediaAudioComponent,
    MediaVideoComponent,
    RichCardComponent,
    VanishingSuggestionComponent,
    SuggestionComponent,
    ImageOverlayComponent,
    CarouselComponent,
    CardContentComponent,
    DetectFocusDirective
  ],
  exports: [ConversationComponent, HtmlEscapePipe],
  entryComponents: [
    ImageOverlayComponent
  ]
})
export class ConversationModule {
}
