import { Component, Input, OnInit } from '@angular/core';
import { CustomerBrandInformation } from '../../conversation/model/customer-brand-information';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {

  @Input() brandInformation: CustomerBrandInformation = new CustomerBrandInformation();

  constructor() { }

  ngOnInit() {
  }
}
