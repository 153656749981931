import { RxStompConfig } from '@stomp/rx-stomp';
import { environment } from '../../../../environments/environment';

export const stompConfig: RxStompConfig = {
  // Which server?
  brokerURL: environment.webservice.url,

  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    login: 'guest',
    passcode: 'guest'
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 30000, // Typical value 0 - disabled
  heartbeatOutgoing: 30000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 5000 (5 seconds)
  reconnectDelay: 5000,

  // Will log diagnostics on console
  debug: (str) => {
    if (environment.webservice.debug) {
      console.log(str);
    }
  }

};
