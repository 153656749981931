import { Component, OnInit } from '@angular/core';
import { CustomerBrandInformation } from '../conversation/model/customer-brand-information';
import { ActivatedRoute } from '@angular/router';
import { BrandInformationService } from '../conversation/services/brand-information.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-information-page',
  templateUrl: './information-page.component.html',
  styleUrls: ['./information-page.component.css']
})
export class InformationPageComponent implements OnInit {

  conversationHash: string;

  brandInformation: CustomerBrandInformation;

  constructor(private route: ActivatedRoute,
              private brandInformationService: BrandInformationService) {
  }

  ngOnInit() {
    if (isNullOrUndefined(this.conversationHash)) {
      this.conversationHash = this.route.snapshot.parent.paramMap.get('hash');
    }
    if (this.conversationHash) {
      this.loadBrandInformation();
    }
    window.scrollTo(0, 0);
  }

  private loadBrandInformation() {
    this.brandInformationService.getBrandInformation(this.conversationHash).then(
      brandinfo => this.brandInformation = brandinfo
    );
  }
}
