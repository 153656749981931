import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarComponent } from './top-bar/top-bar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HtmlEscapePipe } from './pipes/html-escape.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    NoopAnimationsModule,
    RouterModule
  ],
  declarations: [TopBarComponent, HtmlEscapePipe],
  exports: [TopBarComponent, HtmlEscapePipe]
})
export class SharedModule {
}
