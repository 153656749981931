import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractMessage } from '../../model/abstract-message';
import { SafeMediaUrlProviderFactory } from '../../services/images/safe-media-url-provider-factory.service';
import { SafeMediaUrlProvider } from '../../services/images/safe-media-url-provider';
import { SafeUrl } from '@angular/platform-browser';
import { ImageOverlayService } from '../../../shared/image-overlay.service';

@Component({
  selector: 'app-conversation-message-image',
  templateUrl: './media-image.component.html',
  styleUrls: ['./media-image.component.css']
})
export class MediaImageComponent implements OnInit {

  @Input() message: AbstractMessage;
  @Output() imageLoadedEvent: EventEmitter<string> = new EventEmitter<string>();

  safeMediaUrlProvider: SafeMediaUrlProvider;

  constructor(private safeMediaUrlProviderFactory: SafeMediaUrlProviderFactory,
              private imageOverlayService: ImageOverlayService) {
  }

  ngOnInit() {
    this.safeMediaUrlProvider = this.safeMediaUrlProviderFactory.createProvider(this.message.mediaFile);
  }

  getDescription() {
    return (this.message.mediaFile.contentDescription == null) ? '' : this.message.mediaFile.contentDescription;
  }

  hasImage(): boolean {
    return !!this.message.mediaFile.fileUrl || !!this.message.mediaFile.filesId;
  }

  getImageUrl(): Promise<SafeUrl> {
    return this.safeMediaUrlProvider.getMediaUrl();
  }

  getThumbnailUrl(): Promise<SafeUrl> {
   return this.safeMediaUrlProvider.getThumbnailUrl();
  }

  public openImageOverlay() {
    this.getImageUrl().then((url) => {
      this.imageOverlayService.open(url);
    });
  }

  onImageLoaded(): void {
    this.imageLoadedEvent.emit(this.message.id);
  }

  getUrl(): Promise<SafeUrl> {
    return this.message.mediaFile.hasThumbnail() ? this.getThumbnailUrl() : this.getImageUrl();
  }

}
