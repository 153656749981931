import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrandColorComponent } from './brand-color.component';
import { RouterModule } from '@angular/router';
import { InformationPageModule } from '../information-page/information-page.module';
import { ConversationModule } from '../conversation/conversation.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ConversationModule,
    InformationPageModule
  ],
  declarations: [
    BrandColorComponent,
  ]
})
export class BrandColorModule { }
