import { Component, Input, OnInit } from '@angular/core';
import { AgentMessage } from '../../model/agent-message';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  @Input() message: AgentMessage;

  constructor() {
  }

  ngOnInit() {
  }

}
